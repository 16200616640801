import React, { useState, useContext, useEffect } from 'react';
import {useStyletron} from 'baseui';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../../context/app-context';

import Localization from '../../localization';
import Constants from '../../lib/Constants';

import TfCard from '../UI/TfCard';
import Header from '../Header';
import TfToastContainer from '../UI/TfToastContainer';

import CarrierIcon from '../../images/ico-carrier-info.svg';
import CarrierPhone from '../../images/ico-carrier-phone.svg';
import CarrierLink from '../../images/ico-carrier-link.svg';
import HappySelIcon from '../../images/happy_selected.svg';
import HappyIcon from '../../images/happy_smily.svg';
import SadSelIcon from '../../images/sad_selected.svg';
import SadIcon from '../../images/sad_smily.svg';
import DoneIcon from '../../images/ico-general-done@3x.svg';

import { Button, SIZE as ButtonSize, KIND as ButtonKind } from "baseui/button";
import {FormControl} from 'baseui/form-control';
import { Textarea } from "baseui/textarea";
import { PhoneInput } from "baseui/phone-input";
import { Avatar } from "baseui/avatar";
import { KIND as ToastKind } from "baseui/toast";

const Theme1 = (props) => {
  const appContext = useContext(AppContextData);
  const [css] = useStyletron();
  const toastRef = React.useRef(null);
  const [activeId, setActiveId] = useState('0');
  const [showMoreActivity, setShowMoreActivity] = useState(false);
  const [trackDetails, setTrackDetails] = useState(props.trackDetails);
  const [trackValues, setTrackValues] = useState(props.trackValues);
  const [activeTab, setActiveTab] = useState('ship');
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedbackId, setFeedbackId] = useState('');
  const [isSideBanner, setIsSideBanner] = useState(props.isSideBanner);
  const [comments, setComments] = useState('');
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const [country, setCountry] = React.useState({
    id: 'US',
    dialCode: '+1',
    label: 'United States'
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidLoginPhone, setIsValidLoginPhone] = useState(true);
  const showLoginPhoneError = !isValidLoginPhone;
  const [phoneLoginErrorMsg, setPhoneLoginErrorMsg] = useState('');
  const loginPhoneRef = React.useRef(null);
  const [isPhoneUpdating, setIsPhoneUpdating] = useState(false);
  const [smsSuccess, setSmsSuccess] = useState(false);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  useEffect(() => {
    let trackingItems = props.pageDetails.trackingNumbers.split(',');
    if (props.tfTheme.prdRecommend) {
      axios.get(Constants.TRACKFREE.APIURL + sd + '/recommended-products/' + trackingItems[0])
      .then(response => {
        if (response.data.status === 'success') {
          setRecommendedProducts(response.data.recommendedProducts);
        }
      }).catch(error => {
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createShip = () => {
    let trackingItems = props.pageDetails.trackingNumbers.split(',');
    let fields = [];
    let j = 0;
    for (let i in trackingItems) {
      j++;
      fields.push(<span key={i} style={{padding: '0 10px'}}>
        <span
          className={activeId === i ? 'store_multi_label_item store_multi_active' : 'store_multi_label_item'}
          onClick={()=>{setActiveId(i); onShowTracking(i)}}>
          {loc.shipment} {j}
        </span>
      </span>);
    }
    return fields;
  }

  const onShowTracking = (key) => {
    setShowMoreActivity(false);
    props.passLoaded(false);
    let trackingItems = props.pageDetails.trackingNumbers.split(',');
    setTrackDetails(props.trackingData[trackingItems[key]]);
    setTrackValues(props.trackingData[trackingItems[key]].trackDetails.trackValues);

    if (props.trackingData[trackingItems[key]].trackDetails.error) {
      props.passError(props.trackingData[trackingItems[key]].trackDetails.error, props.trackingData[trackingItems[key]]);
    }

    if (props.trackingData[trackingItems[key]].feedback || props.trackingData[trackingItems[key]].smsNotify || recommendedProducts.length > 0 || props.pageDetails.banner) {
      setIsSideBanner(true);
    } else {
      setIsSideBanner(false);
    }
    props.passLoaded(true);
  }

  function showTrackingDetails()
  {
    return (
      <div className="tk-tr-dt">
        <div>
          <div className="store_estimate_section" style={{borderBottomColor: props.tfTheme.borderLinesColor}}>
            <div
              className="store_status"
              // style={{color: props.tfTheme.primaryTextColor}}
              style={{fontFamily: props.tfTheme.primaryTextFont}}
              >{trackDetails.shipmentTitle.status}</div>
            <div
              className={trackDetails.shipmentTitle.days && trackDetails.shipmentTitle.date ? "store_date" : 'tk-ed-na'}
              // style={{color: props.tfTheme.primaryTextColor}}
              style={{fontFamily: props.tfTheme.primaryTextFont}}
              >{trackDetails.shipmentTitle.days}</div>
            <div
              className={trackDetails.currentstatus === 4 ? "store_del_time" : "store_time"}
              // style={{color: props.tfTheme.primaryTextColor}}
              style={{fontFamily: props.tfTheme.primaryTextFont}}
              >{trackDetails.shipmentTitle.date}</div>
          </div>
        </div>
        <div className="tf_store_cont_section" style={{borderBottomColor: props.tfTheme.borderLinesColor}}>
          <div className="store_tracking_section">
            <div>
              <Avatar
                name={trackDetails.courierName}
                size="scale1000"
                src={trackDetails.courierLogo} />
            </div>
            <div className="st-tk-sn" style={{fontFamily: props.tfTheme.secondaryTextFont}}>
              <div
                className="store_tracking_title"
                style={{color: props.tfTheme.secondaryTextColor}}>{loc.trackingNumber}</div>
              <div
                className="store_tracking_num"
                style={{color: props.tfTheme.secondaryTextColor}}>{trackDetails.trackingNum}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="st-op-tb">
            <div
              onClick={()=>setActiveTab('ship')}
              className={activeTab === 'ship' ? 'store_activity_label_item store_multi_active' : 'store_activity_label_item'} style={{fontFamily: props.tfTheme.secondaryTextFont}}>{loc.shipActivity}</div>
            <div
              onClick={()=>setActiveTab('carrier')}
              className={activeTab === 'carrier' ? 'store_activity_label_item store_multi_active' : 'store_activity_label_item'} style={{fontFamily: props.tfTheme.secondaryTextFont}}>{loc.couActivity}</div>
          </div>
          {activeTab === 'ship' && trackValues ?
            <div style={{margin: '0px auto', padding: '20px'}}>
              <div style={{fontFamily: props.secondaryTextFont}}>
                {buildShippingActivity()}
              </div>
              {trackValues.length > 2 ?
                <div style={{margin: '16px 0', textAlign: 'center'}}>
                  <Button
                    kind={ButtonKind.secondary}
                    size={ButtonSize.compact}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          color: props.tfTheme.secondaryBtnTextColor,
                          backgroundColor: props.tfTheme.secondaryBtnColor,
                          fontFamily: "'Open Sans', sans-serif",
                          ':hover': {
                            backgroundColor: props.tfTheme.secondaryBtnColor,
                          }
                        })
                      }
                    }}
                    onClick={()=>{setShowMoreActivity(!showMoreActivity)}}>{showMoreActivity ? loc.hideDesc : loc.showDesc}
                  </Button>
                </div>
              : null}
            </div>
          :
            <div className='tk-ct-sn'>
              {buildContactActivity(trackDetails.carrierContact)}
            </div>
          }
        </div>
      </div>
    );
  }

  function buildShippingActivity() {
    let activityFilter = showMoreActivity ? trackValues : trackValues.slice(0,2);
    let shipFields = [];
    activityFilter.map((item, i) => {
      let dateFields = item.dateTime.split(' ');
      shipFields.push(<div
        key={i}
        className='tp-st-at'>
        <div className="tp-st-ls">
          <span className="store_activity_table_dateTime">{`${dateFields[0]} ${dateFields[1]} ${dateFields[2]}`}</span>
          <span className="store_activity_table_dateTime">{`${dateFields[3] ? dateFields[3] : ''} ${dateFields[4] ? dateFields[4] : ''}`}</span>
        </div>
        <div className="tp-st-ls">
          <span className="store_activity_table_activity">
            <div dangerouslySetInnerHTML={{ __html: item.statusDescription }} />
          </span>
          <span className="store_activity_table_location">{`${item.city}`}</span>
        </div>
      </div>);
      return item;
    });
    return shipFields;
  }

  const buildFeedback = (feedback, shortCode) => {
    return (<div>
      <p
        className="store_feedback_comp"
        // style={{color: tfTheme.primaryTextColor}}
        style={{fontFamily: props.tfTheme.primaryTextFont}}
        >{loc.feedbackTitle}</p>
        {isFeedback ? <div>
          <div><img src={DoneIcon} alt="" /></div>
          <p className="store_feedback_success_title">{loc.feedbackSuccessTitle}!</p>
          <p className="store_feedback_success_desc">{loc.feedbackSuccessDesc}</p>
        </div>:<div>
        <p
          className="store_feedback_desc"
          // style={{color: tfTheme.secondaryTextColor}}
          style={{fontFamily: props.tfTheme.secondaryTextFont}}
          >{loc.feedbackDesc}</p>
        <div className={css({display: 'flex', alignItems: 'center', justifyContent: 'center'})}>
          <div className="fb-details">
            <div
              className="store_feedback_tile_happy"
              onClick={()=>{
                setFeedbackStatus('happy');
                updateFeedback(feedback.happy)
              }}>
              <img
                src={feedbackStatus === 'happy' ? HappySelIcon : HappyIcon}
                height="48"
                width="48"
                alt="" />
            </div>
          </div>
          <div className="fb-details">
            <div
              className="store_feedback_tile_sad"
              onClick={()=>{
                setFeedbackStatus('sad');
                updateFeedback(feedback.unhappy)
              }}>
              <img
                src={feedbackStatus === 'sad' ? SadSelIcon : SadIcon}
                height="48"
                width="48"
                alt="" />
            </div>
          </div>
        </div>
        {feedbackId ?
          <div style={{paddingBottom: '16px'}}>
            <Textarea
              value={comments}
              onChange={e => setComments(e.target.value)}
              placeholder={loc.addAComment}
              maxLength="500"
              overrides={{
                InputContainer: {
                  style: () => ({ backgroundColor: "#FFFFFF" })
                }
              }} />
            <div style={{marginTop: '16px', textAlign: 'left'}}>
              <Button
                overrides={{
                  BaseButton: {
                    style: () => ({
                      width: '100%',
                      color: props.tfTheme.primaryBtnTextColor,
                      backgroundColor: props.tfTheme.primaryBtnColor,
                      fontFamily: "'Open Sans', sans-serif",
                      ':hover': {
                        backgroundColor: props.tfTheme.primaryBtnColor,
                      }
                    })
                  }
                }}
                onClick={()=>onSubmitComment(shortCode)}>
                {loc.sendFeedback}
              </Button>
            </div>
          </div>
          : null
          }
        </div>}
    </div>)
  }

  const buildContactActivity = (activity) => {
    return (
      <div className="tk-bd-cr" style={{fontFamily: props.tfTheme.secondaryTextFont}}>
        <div className="tk-st-ct">
          <div className="tk-cr-ic">
            <img src={CarrierIcon} alt="" width='32' height='32' />
          </div>
          <div className="tk-cr-da">{trackDetails.courierCaption}</div>
        </div>
        {activity.phoneNumber ?
          <div className="tk-st-ct">
            <div className="tk-cr-ic">
              <img src={CarrierPhone} alt="" width='32' height='32' />
            </div>
            <div className="tk-cr-da">{activity.phoneNumber}</div>
          </div>
        : null}
        {activity.website ?
          <div className="tk-st-ct">
            <div className="tk-cr-ic">
              <img src={CarrierLink} alt="" width='32' height='32' />
            </div>
            <div className="tk-cr-da">
              <a
                className="tk-wb-ur"
                href={activity.trackUrl}
                target='_blank'
                rel="noreferrer">{activity.website}</a></div>
          </div>
        : null}
      </div>
    )
  }

  const buildRecommendation = (prods) => {
    let fields = [];

    function updateRecomPrdts(id){
      let tf_source = localStorage.getItem('tf_source');
      axios.get(`${Constants.TRACKFREE.APIURL}rec-prd-clk/${id}?source=${tf_source}`);
    }

    for (let i = 0; i<prods.length; i=i+2) {
      fields.push(<div key={i} className={css({display: 'flex', alignItems: 'center', justifyContent: 'center'})} style={{marginTop:'5px'}}>
        <div className="rp-details">{buildProducts(prods[i])}</div>
        <div className="rp-details">{buildProducts(prods[i+1])}</div>
        </div>)
    }
    return fields;

    function buildProducts(prod) {
      if (prod) {
        let price = parseInt(prod.price);

        return (<div
          className="store_recommend_item"
          style={{borderColor: props.tfTheme.prdThumbBorderColor}}
          onClick={()=>{window.open(prod.handle, '_blank'); updateRecomPrdts(prod.id)}}>
          <img
            src={prod.image}
            width='150'
            height='150'
            style={{objectFit: 'cover'}}
            alt={prod.productAltImgTag} />
          <div className="tk-rp-pn" style={{fontFamily: props.tfTheme.secondaryTextFont}}>{prod.name}</div>
          <div className="tk-rp-pr" style={{fontFamily: props.tfTheme.secondaryTextFont}}>{price.toLocaleString('en-US', {style: 'currency', currency:prod.currency, minimumFractionDigits: 0})}</div>
        </div>)
      } else {
        return(<div></div>);
      }
    }
  }

  const onLoginPhoneValidate = () => {
    setPhoneNumber(phoneNumber.replace(/[^0-9]/g, ''))
  }

  const onCheckPhoneLogin = (shortCode) => {
    if (phoneNumber === '') {
      setIsValidLoginPhone(false);
      loginPhoneRef.current && loginPhoneRef.current.focus();
      setPhoneLoginErrorMsg(loc.enterPhoneNumber);
      return false;
    } else if (phoneNumber.length <= 5) {
      setIsValidLoginPhone(false);
      loginPhoneRef.current && loginPhoneRef.current.focus();
      setPhoneLoginErrorMsg(loc.phoneLimitError);
      return false;
    } else {
      setPhoneLoginErrorMsg('');
      setIsValidLoginPhone(true);
    }

    setIsPhoneUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + 'sms-notify-update',
    {
      tracking_code: shortCode,
      sms_country: country.id,
      sms_country_code: country.dialCode.replace('+', ''),
      sms_notify_number: phoneNumber
    })
    .then(response => {
      if (response.data.status === 'error') {
        toastRef.current.show({ children: response.data.message, kind: ToastKind.negative });
      } else {
        setSmsSuccess(true);
      }
      setIsPhoneUpdating(false);
    }).catch(error => {
    });
  }

  const updateFeedback = (id) => {
    setFeedbackId(id);
  }

  const onSubmitComment = (shortCode) => {
    setIsFeedback(true);
    axios.get(Constants.TRACKFREE.APIURL + 'feedback?fs=' + feedbackId);
    if (comments) {
      axios.post(Constants.TRACKFREE.APIURL + 'comments',
      {
        tracking_code: shortCode,
        comments: comments
      })
      .then(response => {
      }).catch(error => {
      });
    }
  }

  return (<div>
    {appContext.mainPageThemeId ?
      <Header data={appContext} />
    : null}
    {props.isMultiTracking ?
      <div style={{textAlign: 'center'}}>
        <div className="st_ml_tk">
          <div className="store_multi_label" style={{fontFamily: props.tfTheme.primaryTextFont}}>{loc.shipTitle}:</div>
          <div className="st_ml_it" style={{fontFamily: props.tfTheme.primaryTextFont}}>
            {createShip()}
          </div>
        </div>
      </div>
    : null
    }
    <div className="tf-mn-hd">
      {isSideBanner && ((props.tfTheme.feedbackWidget && trackDetails.feedback) || (props.tfTheme.prdRecommend && recommendedProducts.length > 0) || (props.tfTheme.showBanner && props.pageDetails.banner) || (props.tfTheme.mobileDeliveryUpdate && trackDetails.smsNotify)) ?
        <div className="row" style={{margin: '32px 0'}}>
          <div className="col2-8">
            <TfCard bgColor={props.tfTheme.containerBackground}>
              {showTrackingDetails()}
            </TfCard>
          </div>
          <div className="col-4">
            <TfCard bgColor={props.tfTheme.containerBackground}>
              <div className="tk-tr-dt">
                {trackDetails.smsNotify ?
                  <div className="sms_notify" style={{borderBottomColor: props.tfTheme.borderLinesColor}}>
                    <p className="store_feedback_comp" style={{fontFamily: props.tfTheme.primaryTextFont}}>{loc.smsNotify}</p>
                    {smsSuccess ?
                      <div>
                        <div><img src={DoneIcon} alt="" /></div>
                        <p className="store_feedback_success_desc">{loc.smsActiveMsg}</p>
                      </div>
                    :
                      <div>
                        <FormControl
                          error={
                            showLoginPhoneError ? phoneLoginErrorMsg : null}>
                          <PhoneInput
                            error={showLoginPhoneError}
                            inputRef={loginPhoneRef}
                            onBlur={()=>onLoginPhoneValidate()}
                            country={country}
                            autoComplete="off"
                            onCountryChange={({ option }) => setCountry(option)}
                            text={phoneNumber}
                            onTextChange={e => setPhoneNumber(e.currentTarget.value)}
                            overrides={{
                              CountrySelectContainer: {
                                style: () => ({
                                  backgroundColor: "#FFFFFF",
                                })
                              },
                              Input: {
                                props: {
                                  overrides: {
                                    Root: {
                                      style: () => ({
                                        height: '60px',
                                        borderTopLeftRadius: '4px',
                                        borderTopRightRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        borderBottomRightRadius: '4px',
                                      }),
                                    },
                                    Input: {
                                      style: () => ({
                                        backgroundColor: "#FFFFFF",
                                      })
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        </FormControl>
                        <Button
                          overrides={{
                            BaseButton: {
                              style: () => ({
                                color: props.tfTheme.primaryBtnTextColor,
                                backgroundColor: props.tfTheme.primaryBtnColor,
                                fontFamily: "'Open Sans', sans-serif",
                                ':hover': {
                                  backgroundColor: props.tfTheme.primaryBtnColor,
                                }
                              })
                            }
                          }}
                          disabled={isPhoneUpdating ? {} : ''}
                          isLoading={isPhoneUpdating ? {} : ''}
                          onClick={()=>onCheckPhoneLogin(trackDetails.shortCode)}>{loc.getUpdates}</Button>
                      </div>
                    }
                  </div>
                : null
                }

                {props.tfTheme.feedbackWidget && trackDetails.feedback ?
                  <div className="store_feedback" style={{borderBottomColor: props.tfTheme.borderLinesColor}}>
                    {buildFeedback(trackDetails.feedbackData, trackDetails.shortCode)}
                  </div>
                : null
                }
                {props.tfTheme.prdRecommend && recommendedProducts.length > 0 ?
                  <div className="store_recommendation">
                    <div style={{padding:'10px'}}>
                      <p className="store_recommend_title" style={{fontFamily: props.tfTheme.primaryTextFont}}>{loc.moreSee}</p>
                      {buildRecommendation(recommendedProducts)}
                    </div>
                  </div>
                : null
                }

                {props.tfTheme.showBanner && props.pageDetails.banner ?
                  <div className="store_banner">
                    {props.tfTheme.bannerLink ?
                      <a href={props.tfTheme.bannerLink} target='_blank' rel="noreferrer">
                        <img
                          className="store_banner_img"
                          src={props.pageDetails.banner}
                          alt={props.pageDetails.bannerAlt ? props.pageDetails.bannerAlt : ''} />
                      </a>
                    :
                      <img
                        className="store_banner_img"
                        src={props.pageDetails.banner}
                        alt={props.pageDetails.bannerAlt ? props.pageDetails.bannerAlt : ''} />
                    }
                  </div>
                : null
                }
              </div>
            </TfCard>
          </div>
        </div>
      :
        <div className="tk-sn-ct">
          <TfCard bgColor={props.tfTheme.containerBackground}>
            {showTrackingDetails()}
          </TfCard>
        </div>
      }
    </div>
    <TfToastContainer ref={toastRef} />
  </div>);
}

export default Theme1;