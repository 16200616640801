import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../../context/app-context';
import { useParams } from 'react-router-dom';

import Constants from '../../lib/Constants';
import Localization from '../../localization';
import '../../css/Main.css';
import OrderComponent from './OrderComponent';
import Header from '../Header';
import Footer from '../Footer';
import TfCard from '../UI/TfCard';

import {Skeleton} from 'baseui/skeleton';
import {Notification, KIND as NotificationKind} from 'baseui/notification';

const CustomerOrder = () => {
  const appContext = useContext(AppContextData);
  const { shortCode } = useParams();
  const [isInvalidOrder, setIsInvalidOrder] = useState('');
  const [order, setOrder] = useState([]);
  const [isOrderLoaded, setIsOrderLoaded] = useState(false);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  if (appContext.orderLookupPage === false) {
    if (appContext.storeUrl) {
      window.location.href=appContext.storeUrl;
    } else {
      window.location.href='https://trackfree.io/';
    }
  }

  useEffect(() => {
    axios.get(Constants.TRACKFREE.APIURL + sd + '/store-order/' + shortCode)
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidOrder(response.data.message);
      } else {
        setOrder(response.data.orderData);
      }
      setIsOrderLoaded(true);
    }).catch(error => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildDefaultActivity = () => {
    return (
      <div>
        <div>
          <Skeleton
            width="75%"
            height="25px" />
        </div>
        <div style={{margin: '16px 0'}}>
          <Skeleton
            width="50%"
            height="25px" />
        </div>
        <div>
          <Skeleton
            width="25%"
            height="25px" />
        </div>
      </div>
    );
  }

  let orderDetailData = '';
  if (appContext.mainPageThemeId && appContext.orderLookupPage) {
    orderDetailData = <div className="storefront_body" style={{backgroundColor: appContext.styles.bodyBackground}}>
      <Header data={appContext} />
      <div className="tf-mn2-hd">
        <TfCard bgColor={appContext.styles.containerBackground}>
          {isOrderLoaded ?
            <div>
              <div
                className="tf-link"
                style={{fontFamily: appContext.styles.secondaryTextFont}}
                onClick={()=>window.location.href='/'}>{loc.goBack}</div>
              {isInvalidOrder ?
                <div style={{margin: '16px'}}>
                  <Notification
                    kind={NotificationKind.negative}
                    overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isInvalidOrder}</Notification>
                </div>
              :
                <div>
                  <OrderComponent order={order} orderRestricted={false} />
                </div>
              }
            </div>
          : <div style={{margin: '16px'}}>
              {buildDefaultActivity()}
            </div>
          }
        </TfCard>
      </div>
      <Footer
        data={appContext.footerSettings}
        styles={appContext.styles} />
    </div>
  }

  return (<div>
    {orderDetailData}
  </div>);
}

export default CustomerOrder;