import en from "./en";
import de from "./de";
import es from "./es";
import fr from "./fr";
import it from "./it";

const languages = {
  en,
  de,
  es,
  fr,
  it
};

export default languages;