import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Main from './components/Main/Main';
import Orders from './components/Orders/Orders';
import Order from './components/Orders/Order';
import EmailOrder from './components/Orders/EmailOrder';
import CustomerOrder from './components/Orders/CustomerOrder';
import Tracking from './components/Tracking/Tracking';

function App() {
  const routes = <Routes>
    <Route path="/" exact element={<Main />} />
    <Route path="/orders/:uniqueLink" exact element={<Orders />} />
    <Route path="/order/:shortCode" exact element={<Order />} />
    <Route path="/order-detail/:shortCode" exact element={<CustomerOrder />} />
    <Route path="/o/:shortCode/:uniqueCode" exact element={<EmailOrder />} />
    <Route path="/t/:trackingId" exact element={<Tracking />} />
  </Routes>;
    
  return (
    <BrowserRouter>
      <div>
        {routes}
      </div>
    </BrowserRouter>
  );
}

export default App;
