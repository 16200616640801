import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../../context/app-context';

import Constants from '../../lib/Constants';
import Localization from '../../localization';
import '../../css/Main.css';
import OrderComponent from './OrderComponent';
import Header from '../Header';
import Footer from '../Footer';
import TfCard from '../UI/TfCard';
import { useParams } from 'react-router-dom';

import {Skeleton} from 'baseui/skeleton';
import {Notification, KIND as NotificationKind} from 'baseui/notification';
import {Input} from 'baseui/input';
import { Button } from "baseui/button";

const Order = () => {
  const appContext = useContext(AppContextData);
  const { shortCode } = useParams();
  const [isInvalidOrder, setIsInvalidOrder] = useState('');
  const [order, setOrder] = useState([]);
  const [isOrderLoaded, setIsOrderLoaded] = useState(false);
  const [orderRestricted, setOrderRestricted] = useState(true);
  const [showOrderInput, setShowOrderInput] = useState(false);

  const [orderNumber, setOrderNumber] = useState('');
  const [orderEmail, setOrderEmail] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isInvalidInput, setIsInvalidInput] = useState('');
  const [isValidOrderNumber, setIsValidOrderNumber] = useState(true);
  const showOrderNumberError = !isValidOrderNumber;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const showEmailError = !isValidEmail;

  const emailRef = React.useRef(null);
  const orderNumberRef = React.useRef(null);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  if (appContext.orderLookupPage === false) {
    if (appContext.storeUrl) {
      window.location.href=appContext.storeUrl;
    } else {
      window.location.href='https://trackfree.io/';
    }
  }

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  useEffect(() => {
    const tfOrdToken = localStorage.getItem('tf_ord_token');
    if (tfOrdToken) {
      if (tfOrdToken === shortCode) {
        setOrderRestricted(false);
        setShowOrderInput(false);
      } else {
        setOrderRestricted(true);
        setShowOrderInput(true);
      }
    } else {
      setOrderRestricted(true);
      setShowOrderInput(true);
    }

    axios.get(Constants.TRACKFREE.APIURL + sd + '/store-order/' + shortCode)
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidOrder(response.data.message);
      } else {
        setOrder(response.data.orderData);
      }
      setIsOrderLoaded(true);
    }).catch(error => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildDefaultActivity = () => {
    return (
      <div>
        <div>
          <Skeleton
            width="75%"
            height="25px" />
        </div>
        <div style={{margin: '16px 0'}}>
          <Skeleton
            width="50%"
            height="25px" />
        </div>
        <div>
          <Skeleton
            width="25%"
            height="25px" />
        </div>
      </div>
    );
  }

  const onCheckOrder = () => {
    setIsInvalidInput('');
    if (!orderNumber) {
      setIsValidOrderNumber(false);
      orderNumberRef.current && orderNumberRef.current.focus();
      return false;
    } else {
      setIsValidOrderNumber(true);
    }

    var validator = require("email-validator");
    if (validator.validate(orderEmail) === false) {
      setIsValidEmail(false);
      emailRef.current && emailRef.current.focus();
      return false;
    } else {
      setIsValidEmail(true);
    }
    setIsUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + sd + '/check-store-order',
    {
      order_id: orderNumber,
      email: orderEmail,
      short_code: shortCode
    })
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidInput(response.data.message);
      } else {
        localStorage.setItem('tf_ord_token', shortCode);
        setShowOrderInput(false);
        setOrderRestricted(false);
      }
      setIsUpdating(false);
    }).catch(error => {
    });
  }

  let orderDetailData = '';
  if (appContext.mainPageThemeId && appContext.orderLookupPage) {
    orderDetailData = <div className="storefront_body" style={{backgroundColor: appContext.styles.bodyBackground}}>
      <Header data={appContext} />
      <div className="tf-mn2-hd">
        <TfCard bgColor={appContext.styles.containerBackground}>
          {isOrderLoaded ?
            <div>
              {isInvalidOrder ?
                <div style={{margin: '16px'}}>
                  <Notification
                    kind={NotificationKind.negative}
                    overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isInvalidOrder}</Notification>
                </div>
              :
                <div>
                  {showOrderInput ?
                    <div className="ord-log-inp">
                      <div>{loc.loginToViewOrdDet}</div>
                      {isInvalidInput ?
                        <div style={{margin: '16px'}}>
                          <Notification
                            kind={NotificationKind.negative}
                            overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isInvalidInput}</Notification>
                        </div> : null
                      }
                      <div className="ord-inp-fld">
                        <div>
                          <Input
                            placeholder={loc.orderNumber}
                            autoComplete="off"
                            error={showOrderNumberError}
                            inputRef={orderNumberRef}
                            onChange={e => setOrderNumber(e.target.value)}
                            value={orderNumber} />
                        </div>
                        <div style={{margin: '0 12px' }}>
                          <Input
                            placeholder={loc.email}
                            autoComplete="off"
                            error={showEmailError}
                            onChange={e => setOrderEmail(e.target.value)}
                            value={orderEmail} />
                        </div>
                        <div>
                          <Button
                            disabled={isUpdating ? {} : ''}
                            isLoading={isUpdating ? {} : ''}
                            onClick={()=>onCheckOrder()}>{loc.submit}</Button>
                        </div>
                      </div>
                    </div>
                  : null}
                  <OrderComponent order={order} orderRestricted={orderRestricted} />
                </div>
              }
            </div>
          : <div style={{margin: '16px'}}>
              {buildDefaultActivity()}
            </div>
          }
        </TfCard>
      </div>
      <Footer
        data={appContext.footerSettings}
        styles={appContext.styles} />
    </div>
  }

  return (<div>
    {orderDetailData}
  </div>);
}

export default Order;