import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../../context/app-context';
import { useNavigate, useLocation } from 'react-router-dom';

import Localization from '../../localization';
import Constants from '../../lib/Constants';

import Styles from '../../lib/Sytles';
import TfCard from '../UI/TfCard';

import GoogleImage from '../../images/google.svg';
import FbImage from '../../images/facebook.svg';

import {FormControl} from 'baseui/form-control';
import {Input, SIZE as InputSize} from 'baseui/input';
import { Button, SIZE as ButtonSize } from "baseui/button";
import {Notification, KIND as NotificationKind} from 'baseui/notification';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";

const MainPage1 = (props) => {
  const appContext = useContext(AppContextData);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("0");
  const [orderNumber, setOrderNumber] = useState('');
  const [orderEmail, setOrderEmail] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isInvalidOrder, setIsInvalidOrder] = useState(false);
  const [isValidOrderNumber, setIsValidOrderNumber] = useState(true);
  const showOrderNumberError = !isValidOrderNumber;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const showEmailError = !isValidEmail;

  const emailRef = React.useRef(null);
  const orderNumberRef = React.useRef(null);

  const [orderSendEmail, setOrderSendEmail] = useState('');
  const [isEmailOrderUpdating, setIsEmailOrderUpdating] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState('');
  const [isSuccessEmail, setIsSuccessEmail] = useState('');
  const [isValidSendEmail, setIsValidSendEmail] = useState(true);
  const showSendEmailError = !isValidSendEmail;

  const [trackingNumber, setTrackingNumber] = useState('');
  const [isInvalidTracking, setIsInvalidTracking] = useState(false);
  const [isValidTrackingNumber, setIsValidTrackingNumber] = useState(true);
  const showTrackingNumberError = !isValidTrackingNumber;

  const trackingNumberRef = React.useRef(null);

  const emailSendRef = React.useRef(null);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  useEffect(() => {
    if (location.state && location.state.from && location.state.from === 'tracking') {
      setActiveKey('1');
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location.state]);

  const onCheckOrder = () => {
    setIsInvalidOrder(false);
    if (!orderNumber) {
      setIsValidOrderNumber(false);
      orderNumberRef.current && orderNumberRef.current.focus();
      return false;
    } else {
      setIsValidOrderNumber(true);
    }

    var validator = require("email-validator");
    if (validator.validate(orderEmail) === false) {
      setIsValidEmail(false);
      emailRef.current && emailRef.current.focus();
      return false;
    } else {
      setIsValidEmail(true);
    }
    setIsUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + sd + '/get-order-code',
    {
      order_id: orderNumber,
      email: orderEmail
    })
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidOrder(true);
      } else {
        window.location.href=`/order-detail/${response.data.orderCode}`;
      }
      setIsUpdating(false);
    }).catch(error => {
    });
  }

  const onSendEmailLink = () => {
    setIsInvalidEmail('');
    setIsSuccessEmail('');
    var validator = require("email-validator");
    if (validator.validate(orderSendEmail) === false) {
      setIsValidSendEmail(false);
      emailSendRef.current && emailSendRef.current.focus();
      return false;
    } else {
      setIsValidSendEmail(true);
    }
    setIsEmailOrderUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + sd + '/store-order-status',
    {
      email: orderSendEmail
    })
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidEmail(response.data.message);
      } else {
        setIsSuccessEmail(response.data.message);
      }
      setIsEmailOrderUpdating(false);
    }).catch(error => {
    });
  }

  const onCheckTracking = () => {
    setIsInvalidTracking(false);
    if (!trackingNumber) {
      setIsValidTrackingNumber(false);
      trackingNumberRef.current && trackingNumberRef.current.focus();
      return false;
    } else {
      setIsValidTrackingNumber(true);
    }
    setIsUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + sd + '/get-tracking-data',
    {
      tracking_number: trackingNumber
    })
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidTracking(true);
      } else {
        navigate(`/t/${trackingNumber}`, { state: { from: 'home' } });
      }
      setIsUpdating(false);
    }).catch(error => {
    });
  }

  let enterOrder = loc.enterOrderNumber;
  if (props.content.enter_order_number) {
    enterOrder = props.content.enter_order_number;
  }

  let enterEmail = loc.enterValidEmail;
  if (props.content.enter_email) {
    enterEmail = props.content.enter_email;
  }

  const orderDetails = 
    <div className='tf-mt-16'>
      <div className="order_desc" style={{fontFamily: props.styles.secondaryTextFont}}>{props.content.enter_order_number_email ? props.content.enter_order_number_email : loc.trakOrderDesc}</div>
      <div className="order_form">
        <FormControl
          label={props.content.order_number ? props.content.order_number : loc.orderNumber}
          overrides={Styles.FormLabel}
          error={
            showOrderNumberError ? enterOrder : null}>
          <Input
            autoComplete="off"
            size={InputSize.large}
            overrides={Styles.FormInput}
            error={showOrderNumberError}
            inputRef={orderNumberRef}
            onChange={e => setOrderNumber(e.target.value)}
            value={orderNumber} />
        </FormControl>
        <FormControl
          label={props.content.email ? props.content.email : loc.email}
          overrides={Styles.FormLabel}
          error={
            showEmailError ? enterEmail : null}>
          <Input
            autoComplete="off"
            size={InputSize.large}
            overrides={Styles.FormInput}
            error={showEmailError}
            onChange={e => setOrderEmail(e.target.value)}
            value={orderEmail} />
        </FormControl>
        {isInvalidOrder ?
          <div style={{margin: '16px 0'}}>
            <Notification
              kind={NotificationKind.negative}
              overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{props.content.order_not_found ? props.content.order_not_found : loc.orderNotFound}</Notification>
          </div>
        : null}
        <Button
          overrides={{
            BaseButton: {
              style: () => ({
                width: '100%',
                color: props.styles.primaryBtnTextColor,
                backgroundColor: props.styles.primaryBtnColor,
                fontFamily: "'Open Sans', sans-serif",
                ':hover': {
                  backgroundColor: props.styles.primaryBtnColor,
                }
              })
            }
          }}
          size={ButtonSize.large}
          disabled={isUpdating ? {} : ''}
          isLoading={isUpdating ? {} : ''}
          onClick={()=>onCheckOrder()}>{props.content.order_look_up ? props.content.order_look_up : loc.orderLookup}</Button>
      </div>
      {!appContext.hideAllOrdersSection ?
        <div>
          <div className="separator">
              <span>{props.content.or ? props.content.or : loc.or}</span>
          </div>
          <div className="order_desc" style={{marginTop: '32px', fontFamily: props.styles.secondaryTextFont}}>{props.content.enter_email_all_orders ? props.content.enter_email_all_orders : loc.trakOrderEmailDesc}</div>
          <div className="order_form">
            <FormControl
              label={props.content.email ? props.content.email : loc.email}
              overrides={Styles.FormLabel}
              error={
                showSendEmailError ? enterEmail : null}>
              <Input
                autoComplete="off"
                size={InputSize.large}
                overrides={Styles.FormInput}
                error={showSendEmailError}
                onChange={e => setOrderSendEmail(e.target.value)}
                value={orderSendEmail} />
            </FormControl>
            {isInvalidEmail ?
              <div style={{margin: '16px 0'}}>
                <Notification
                  kind={NotificationKind.negative}
                  overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isInvalidEmail}</Notification>
              </div>
            : null}
            {isSuccessEmail ?
              <div style={{margin: '16px 0'}}>
                <Notification
                  kind={NotificationKind.positive}
                  overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isSuccessEmail}</Notification>
              </div>
            : null}
            <Button
              overrides={{
                BaseButton: {
                  style: () => ({
                    width: '100%',
                    color: props.styles.primaryBtnTextColor,
                    backgroundColor: props.styles.primaryBtnColor,
                    fontFamily: "'Open Sans', sans-serif",
                    ':hover': {
                      backgroundColor: props.styles.primaryBtnColor,
                    }
                  })
                }
              }}
              size={ButtonSize.large}
              disabled={isEmailOrderUpdating ? {} : ''}
              isLoading={isEmailOrderUpdating ? {} : ''}
              onClick={()=>onSendEmailLink()}>{props.content.continue ? props.content.continue : loc.continue}</Button>
            <div style={{marginTop: '32px'}}>
              <Button
                onClick={()=>window.location.href=`${Constants.TRACKFREE.DOMAIN_URL}sf-login/google/${appContext.st}`}
                size={ButtonSize.large}
                overrides={{
                  BaseButton: {
                    style: () => ({
                      width: '100%',
                      color: props.styles.primaryBtnTextColor,
                      backgroundColor: props.styles.primaryBtnColor,
                      fontFamily: "'Open Sans', sans-serif",
                      ':hover': {
                        backgroundColor: props.styles.primaryBtnColor,
                      }
                    })
                  }
                }}><img src={GoogleImage} alt="" style={{marginRight: '8px'}} />{props.content.continue_with_google ? props.content.continue_with_google : loc.formatString(loc.connectWith, 'Google')}</Button>
            </div>
            <div style={{marginTop: '16px'}}>
              <Button
                onClick={()=>window.location.href=`${Constants.TRACKFREE.DOMAIN_URL}sf-login/facebook/${appContext.st}`}
                size={ButtonSize.large}
                overrides={{
                  BaseButton: {
                    style: () => ({
                      width: '100%',
                      color: props.styles.primaryBtnTextColor,
                      backgroundColor: props.styles.primaryBtnColor,
                      fontFamily: "'Open Sans', sans-serif",
                      ':hover': {
                        backgroundColor: props.styles.primaryBtnColor,
                      }
                    })
                  }
                }}><img src={FbImage} alt="" style={{marginRight: '8px'}} />{props.content.continue_with_facebook ? props.content.continue_with_facebook : loc.formatString(loc.connectWith, 'Facebook')}</Button>
            </div>
          </div>
        </div>
      : null}
    </div>

  const trackingDetails =
    <div className='tf-mt-16'>
      <div className="order_desc" style={{fontFamily: props.styles.secondaryTextFont}}>{props.content.check_shipment_status ? props.content.check_shipment_status : loc.trackWtihNumDesc}</div>
      <div className="order_form">
        <FormControl
          label={props.content.tracking_number ? props.content.tracking_number : loc.trackNum}
          overrides={Styles.FormLabel}
          error={
            showTrackingNumberError ? props.content.enter_tracking_number ? props.content.enter_tracking_number : loc.enterValidTrackNo : null}>
          <Input
            autoComplete="off"
            size={InputSize.large}
            overrides={Styles.FormInput}
            error={showTrackingNumberError}
            inputRef={trackingNumberRef}
            onChange={e => setTrackingNumber(e.target.value)}
            value={trackingNumber} />
        </FormControl>
        {isInvalidTracking ?
          <div style={{margin: '16px 0'}}>
            <Notification
              kind={NotificationKind.negative}
              overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{props.content.no_info_available ? props.content.no_info_available : loc.noInfoAvailTrack}</Notification>
          </div>
        : null}
        <Button
          overrides={{
            BaseButton: {
              style: () => ({
                width: '100%',
                color: props.styles.primaryBtnTextColor,
                backgroundColor: props.styles.primaryBtnColor,
                fontFamily: "'Open Sans', sans-serif",
                ':hover': {
                  backgroundColor: props.styles.primaryBtnColor,
                }
              })
            }
          }}
          size={ButtonSize.large}
          disabled={isUpdating ? {} : ''}
          isLoading={isUpdating ? {} : ''}
          onClick={()=>onCheckTracking()}>{props.content.track ? props.content.track : loc.track}</Button>
      </div>
    </div>

  return(
    <div className="tf-mn2-hd">
      <TfCard bgColor={props.styles.containerBackground}>
        <div className="order_title" style={{fontFamily: props.styles.primaryTextFont}}>{props.content.track_your_order ? props.content.track_your_order : loc.trackOrder}</div>
        {appContext.enableTrackingWidget ?
          <div className='tk-tb-ct'>
            <Tabs
              activeKey={activeKey}
              onChange={({ activeKey }) => {
                setActiveKey(activeKey);
              }}
              fill={FILL.fixed}
              activateOnFocus
            >
              <Tab title={props.content.title_order_number ? props.content.title_order_number : loc.ordNum}>{orderDetails}</Tab>
              <Tab title={props.content.title_tracking_number ? props.content.title_tracking_number : loc.trackingNumber}>{trackingDetails}</Tab>
            </Tabs>
          </div>
        :
          <div>
            {orderDetails}
          </div>
        }
      </TfCard>
    </div>
  );
}

export default MainPage1;