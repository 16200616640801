const localizeObject = {
  trackOrder: 'Track your order',
  trakOrderDesc: 'Enter the order number and email address used for the order.',
  orderNumber: 'Order number',
  enterOrderNumber: 'Please enter your order number',
  email: 'Email',
  enterValidEmail: 'Please enter a valid email address',
  orderLookup: 'Order look up',
  orderNotFound: 'Order not found',
  order: 'Order',
  thankYou: 'Thank you',
  customerInfo: 'Customer information',
  contactInfo: 'Contact information',
  shippingAddress: 'Shipping address',
  shippingMethod: 'Shipping method',
  paymentMethod: 'Payment method',
  orderStatus: 'Order status',
  trakOrderEmailDesc: 'Enter your email to view all your orders. We will send you an email',
  submit: 'Submit',
  orderHistory: 'Order history',
  goBack: 'Go back',
  loginToViewOrdDet: 'Enter email and order number to view all order details',
  trackOrderStatus: 'Track order status',
  ordNum: 'Order Number',
  magicLink: 'Magic Link',
  connectWith: 'Continue with {0}',
  bestSellers: 'Best Sellers',
  or: 'or',
  continue: 'Continue',
  tracking: 'Tracking',
  shipActivity: 'Shipping Activity',
  couActivity: 'Contact Carrier',
  feedbackTitle: 'HELP US IMPROVE',
  feedbackDesc: 'Rate your shipping experience',
  satisfied: 'Satisfied',
  notSatisfied: 'Not Satisfied',
  shipTitle: 'Your order will arrive in multiple shipments',
  shipment: 'Shipment',
  hideDesc: 'Hide all shipping activity',
  showDesc: 'See all shipping activity',
  domainError: 'Domain not found',
  estimate: 'ESTIMATED DATE',
  serviceError: 'Service Unavailable',
  feedbackSuccess: 'Feedback submitted successfully, Thank you',
  no_tracking_details: 'No tracking details available',
  domain_not_exist: 'Domain not found',
  invalid_tracking_number: 'Invalid tracking number',
  copied: 'Copied!',
  copy: 'Copy',
  webTrack: 'Track on carrier website',
  backTrack: 'Back to website',
  trackAt: 'Track your package at',
  instead: 'instead',
  moreSee: "There’s more to see",
  feedbackSuccessTitle: 'THANK YOU',
  feedbackSuccessDesc: 'Your feedback helps improve our delivery service',
  delivered: 'Delivered',
  shipped: 'Shipped',
  orderPlaced: 'Order placed',
  addComment: 'Add comments',
  smsNotify: 'SMS Notification',
  enterPhoneNumber: 'Please enter your phone number',
  phoneLimitError: 'Phone number is too short or contains invalid characters.',
  smsActiveMsg: 'You will get SMS when shipment updated',
  trackingNumber: 'Tracking Number',
  sendFeedback: 'Send Feedback',
  addAComment: 'Add a comment (optional)',
  contactUs: 'Contact us',
  terms: 'Terms',
  privacy: 'Privacy',
  getUpdates: 'Get Updates',
  trackWtihNumDesc: "Check your shipment status by entering your tracking number",
  enterValidTrackNo: "Enter a valid tracking number",
  noInfoAvailTrack: "No information available for this tracking number",
  track: "Track",
  trackNum: "Tracking number",
};

export default localizeObject;