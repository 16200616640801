import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {useStyletron} from 'baseui';
import LocalizedStrings from 'react-localization';
import { useSearchParams, useParams } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { AppContextData } from '../../context/app-context';
import { useLocation } from 'react-router-dom';

import '../../css/Tracking.css';
import Constants from '../../lib/Constants';
import Localization from '../../localization';
import TfToastContainer from '../UI/TfToastContainer';

import Theme1 from './Theme1';
import Theme2 from './Theme2';
import Theme3 from './Theme3';
import Theme4 from './Theme4';
import Theme5 from './Theme5';
import Theme6 from './Theme6';
import Footer from '../Footer';

import { Button, KIND as ButtonKind, SHAPE } from "baseui/button";
import { Modal, ModalBody, SIZE } from 'baseui/modal';
import {Input} from 'baseui/input';
import { KIND as ToastKind } from "baseui/toast";
import { Skeleton } from "baseui/skeleton";

const Tracking = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const fs = searchParams.get('fs');
  const source = searchParams.get('s');
  const appContext = useContext(AppContextData);
  const [css] = useStyletron();
  const toastRef = React.useRef(null);
  const { trackingId } = useParams();
  const trackId = trackingId;
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageDetails, setPageDetails] = useState([]);
  const [trackDetails, setTrackDetails] = useState([]);
  const [trackValues, setTrackValues] = useState([]);
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorDetails, setErrorDetails] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSideBanner, setIsSideBanner] = useState(false);
  const [theme2Columns, setTheme2Columns] = useState(1);
  const [isMultiTracking, setIsMultiTracking] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [tfTheme, setTfTheme] = useState([]);
  const [cameFromHomePage, setCameFromHomePage] = useState(false);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  const errorCallback = (err, data) => {
    setIsErrorModalOpen(true);
    setIsError(true);
    setErrorDetails(err);
    setTrackDetails(data);
  }

  const loadCallback = (val) => {
    setIsLoaded(val);
  }

  useEffect(() => {
    if (location.state && location.state.from && location.state.from === 'home') {
      setCameFromHomePage(true);
    }
}, [location.state]);

  useEffect(() => {
    if (fs) {
      axios.get(`${Constants.TRACKFREE.APIURL}feedback?fs=${fs}`)
      .then(response => {
        toastRef.current.show({ children: loc.feedbackSuccess, kind: ToastKind.positive });
      });
    }

    if (source) {
      localStorage.setItem('tf_source', 'mail');
    } else {
      localStorage.setItem('tf_source', 'web');
    }

    let url = window.location.href;
    let subdomain = url.split('/')[2];

    const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

    axios.get(Constants.TRACKFREE.APIURL + sd + '/tracking/' + trackId + '?lang=' + lng)
    .then(response => {
      document.title = response.data.page.title;
      let metaTag = document.createElement('meta');
      metaTag.name = 'description';
      metaTag.content = response.data.page.description;
      document.head.appendChild(metaTag);

      setPageDetails(response.data.page);
      setTfTheme(response.data.theme);
      setTrackingData(response.data);
      let trackingItems = response.data.page.trackingNumbers.split(',');

      if (trackingItems.length > 1) {
        setIsMultiTracking(true);
        setTrackDetails(response.data[trackingItems[0]]);
        setTrackValues(response.data[trackingItems[0]].trackDetails.trackValues);
        setTrackingDetails(response.data[trackingItems[0]].trackDetails.trackingDetails);

        if (response.data[trackingItems[0]].trackDetails.error) {
          setIsError(true);
          setErrorDetails(response.data[trackingItems[0]].trackDetails.error);
          setIsErrorModalOpen(true);
        }

        if (response.data[trackingItems[0]].feedback || response.data[trackingItems[0]].smsNotify || response.data.theme.prdRecommend || response.data.theme.showBanner) {
          setIsSideBanner(true);
        }
        if ((response.data[trackingItems[0]].feedback || response.data[trackingItems[0]].smsNotify) && response.data.theme.showBanner) {
          setTheme2Columns(3);
        } else if ((response.data[trackingItems[0]].feedback || response.data[trackingItems[0]].smsNotify) && !response.data.theme.showBanner) {
          setTheme2Columns(2);
        } else if ((response.data[trackingItems[0]].feedback || response.data[trackingItems[0]].smsNotify) || response.data.theme.showBanner) {
          setTheme2Columns(2);
        }
        setIsLoaded(true);
      } else {
        setIsMultiTracking(false);
        setTrackDetails(response.data[response.data.page.trackingNumbers]);
        setTrackValues(response.data[response.data.page.trackingNumbers].trackDetails.trackValues);
        setTrackingDetails(response.data[response.data.page.trackingNumbers].trackDetails.trackingDetails);

        if (response.data[response.data.page.trackingNumbers].trackDetails.error) {
          setIsError(true);
          setErrorDetails(response.data[response.data.page.trackingNumbers].trackDetails.error);
          setIsErrorModalOpen(true);
        }

        if (response.data[response.data.page.trackingNumbers].feedback || response.data[response.data.page.trackingNumbers].smsNotify || response.data.theme.prdRecommend || response.data.theme.showBanner) {
          setIsSideBanner(true);
        }
        if ((response.data[response.data.page.trackingNumbers].feedback || response.data[response.data.page.trackingNumbers].smsNotify) && response.data.theme.showBanner) {
          setTheme2Columns(3);
        } else if ((response.data[response.data.page.trackingNumbers].feedback || response.data[response.data.page.trackingNumbers].smsNotify) && !response.data.theme.showBanner) {
          setTheme2Columns(2);
        } else if ((response.data[trackingItems[0]].feedback || response.data[trackingItems[0]].smsNotify) || !response.data.theme.showBanner) {
          setTheme2Columns(2);
        }
        setIsLoaded(true);
      }
    })
    .catch(error => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopyTrackingNum = (trackingNum) => {
    navigator.clipboard.writeText(trackingNum);
    toastRef.current.show({ children: loc.copied, kind: ToastKind.positive });
  }

  let trackDetailsData = <div className="storefront_body">
    <div className="storeHeader">
      <div className="store_load_logo"></div>
    </div>
    <div className="container store_container">
      <div className="store_estimate_section" style={{display: 'flex', justifyContent: 'center'}}>
        <Skeleton height="50px" width="50%" animation />
      </div>
      <div className="tf_store_cont_section">
        <div className="store_tracking_section">
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Skeleton
              width="75px"
              height="75px"
              animation
              overrides={{
                Root: {
                  style: {
                    borderRadius: '50%',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div style={{margin: '32px 0'}}>
        <div>
          <Skeleton
            rows={1}
            width="50%"
            animation
            overrides={{
              Row: {
                style: {
                  height: '20px',
                  marginBottom: '15px',
                  marginLeft: '200px',
                },
              },
            }}
          />
        </div>
        <div>
          <Skeleton
            rows={1}
            width="40%"
            animation
            overrides={{
              Row: {
                style: {
                  height: '20px',
                  marginBottom: '15px',
                  marginLeft: '200px',
                },
              },
            }}
          />
        </div>
        <div>
          <Skeleton
            rows={1}
            width="30%"
            animation
            overrides={{
              Row: {
                style: {
                  height: '20px',
                  marginBottom: '15px',
                  marginLeft: '200px',
                },
              },
            }}
          />
        </div>
      </div>
      <div style={{margin: '32px 0'}}>
        <div>
          <Skeleton
            rows={1}
            width="50%"
            animation
            overrides={{
              Row: {
                style: {
                  height: '20px',
                  marginBottom: '15px',
                  marginLeft: '200px',
                },
              },
            }}
          />
        </div>
        <div>
          <Skeleton
            rows={1}
            width="40%"
            animation
            overrides={{
              Row: {
                style: {
                  height: '20px',
                  marginBottom: '15px',
                  marginLeft: '200px',
                },
              },
            }}
          />
        </div>
        <div>
          <Skeleton
            rows={1}
            width="30%"
            animation
            overrides={{
              Row: {
                style: {
                  height: '20px',
                  marginBottom: '15px',
                  marginLeft: '200px',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  </div>;

  if (isLoaded && !isError) {
    trackDetailsData = <div className="storefront_body" style={{backgroundColor: tfTheme.bodyBackground}}>
      {pageDetails.fbChatAppId && pageDetails.fbChatPageId ?
        <MessengerCustomerChat
            pageId={pageDetails.fbChatPageId}
            appId={pageDetails.fbChatAppId}
          />
      : null
      }
      {pageDetails.theme === '1' ?
        <Theme1
          tfTheme={tfTheme}
          pageDetails={pageDetails}
          isMultiTracking={isMultiTracking}
          trackDetails={trackDetails}
          trackValues={trackValues}
          isSideBanner={isSideBanner}
          trackingData={trackingData}
          passLoaded={loadCallback}
          passError={errorCallback}
        />
      : null}
      {pageDetails.theme === '2' ?
        <Theme2
          tfTheme={tfTheme}
          pageDetails={pageDetails}
          isMultiTracking={isMultiTracking}
          trackDetails={trackDetails}
          trackValues={trackValues}
          trackingData={trackingData}
          passLoaded={loadCallback}
          passError={errorCallback}
          theme2Columns={theme2Columns}
        />
      : null}
      {pageDetails.theme === '3' ?
        <Theme3
          tfTheme={tfTheme}
          pageDetails={pageDetails}
          isMultiTracking={isMultiTracking}
          trackDetails={trackDetails}
          trackValues={trackValues}
          trackingDetails={trackingDetails}
          isSideBanner={isSideBanner}
          trackingData={trackingData}
          passLoaded={loadCallback}
          passError={errorCallback}
        />
      : null}

      {pageDetails.theme === '4' ?
        <Theme4
          tfTheme={tfTheme}
          pageDetails={pageDetails}
          isMultiTracking={isMultiTracking}
          trackDetails={trackDetails}
          trackValues={trackValues}
          isSideBanner={isSideBanner}
          trackingData={trackingData}
          passLoaded={loadCallback}
          passError={errorCallback}
        />
      : null}

      {pageDetails.theme === '5' ?
        <Theme5
          tfTheme={tfTheme}
          pageDetails={pageDetails}
          isMultiTracking={isMultiTracking}
          trackDetails={trackDetails}
          trackValues={trackValues}
          isSideBanner={isSideBanner}
          trackingData={trackingData}
          passLoaded={loadCallback}
          passError={errorCallback}
        />
      : null}

      {pageDetails.theme === '6' ?
        <Theme6
          tfTheme={tfTheme}
          pageDetails={pageDetails}
          isMultiTracking={isMultiTracking}
          trackDetails={trackDetails}
          trackValues={trackValues}
          isSideBanner={isSideBanner}
          trackingData={trackingData}
          passLoaded={loadCallback}
          passError={errorCallback}
        />
      : null}

      {appContext.mainPageThemeId ?
        <Footer
          data={appContext.footerSettings}
          styles={appContext.styles}
          cameFromHomePage={cameFromHomePage} />
      : null}

      {tfTheme.supportButton ?
        <div className="tf-tm-sp-bn" style={{bottom: pageDetails.fbChatAppId && pageDetails.fbChatPageId ? '85px' : '0'}}>
          <Button
            onClick={tfTheme.supportWebPageLink ? ()=>window.open(tfTheme.supportWebPageLink) : null}
            overrides={{
              BaseButton: {
                style: () => ({
                  marginLeft: '1px',
                  color: tfTheme.supportTextColor,
                  backgroundColor: tfTheme.supportBtnColor,
                  fontFamily: "'Open Sans', sans-serif",
                  ':hover': {
                    backgroundColor: tfTheme.supportBtnColor,
                  }
                })
              }
            }}
            shape={SHAPE.pill}
            kind={ButtonKind.secondary}>
            Support
          </Button>
        </div>
      : null}
    </div>
  }

  return (
    <div>
      {trackDetailsData}
      <Modal
        isOpen={isErrorModalOpen}
        size={SIZE.default}
        closeable={false}>
        <ModalBody>
          <div className="tf-modal-header">
            <div style={{textAlign: 'center'}}>
              <div className="tf-modal-title">{trackDetails.overlayTitle}</div>
            </div>
          </div>
          <div className="tf-modal-error-heading">
            {errorDetails}
          </div>
          <div className="tf-modal-footer">
            {trackDetails.overlayMessage === 'no_tracking_details' ?
              <React.Fragment>
                <p style={{fontSize: '18px'}}>{loc.trackAt} <b>{trackDetails.courierCaption}</b> {loc.instead}</p>
                <div className={css({display: 'flex', alignItems: 'center', justifyContent: 'center'})} style={{marginBottom: '24px'}}>
                  <Input
                    disabled
                    value={trackDetails.trackingNum}
                    overrides={{
                      Root: {
                        style: {
                          width: 'auto',
                        },
                      },
                    }}
                  />
                  <Button
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          color: tfTheme.primaryBtnTextColor,
                          backgroundColor: tfTheme.primaryBtnColor,
                          fontFamily: "'Open Sans', sans-serif",
                          ':hover': {
                            backgroundColor: tfTheme.primaryBtnColor,
                          }
                        })
                      }
                    }}
                    onClick={()=>onCopyTrackingNum(trackDetails.trackingNum)}
                    kind={ButtonKind.secondary}>
                    {loc.copy}
                  </Button>
                </div>
              </React.Fragment>
            : null
            }
            <Button
              onClick={()=>trackDetails.overlayLink ?
              window.open(trackDetails.overlayLink, '_blank') : null}
              overrides={{
                BaseButton: {
                  style: () => ({
                    backgroundColor: "rgb(79, 93, 186)",
                    fontFamily: "'Open Sans', sans-serif",
                    ':hover': {
                      backgroundColor: 'rgb(79, 93, 186)',
                    }
                  })
                }
              }}>
              {trackDetails.overlayBtnTitle}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <TfToastContainer ref={toastRef} />
    </div>
  );
}

export default Tracking;