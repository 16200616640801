const localizeObject = {
  trackOrder: 'Suivre votre commande',
  trakOrderDesc: "Entrez le numéro de commande et l'adresse e-mail utilisés pour la commande.",
  orderNumber: 'Numéro de commande',
  enterOrderNumber: 'Veuillez saisir votre numéro de commande',
  email: 'E-mail',
  enterValidEmail: "S'il vous plaît, mettez une adresse email valide",
  orderLookup: 'Rechercher une commande',
  orderNotFound: 'Commande introuvable',
  order: 'Commander',
  thankYou: 'Merci',
  customerInfo: 'Informations client',
  contactInfo: 'Informations de contact',
  shippingAddress: 'Adresse de livraison',
  shippingMethod: 'Mode de livraison',
  paymentMethod: 'Mode de paiement',
  orderStatus: 'Statut de la commande',
  trakOrderEmailDesc: 'Entrez votre email pour voir toutes vos commandes. Nous vous enverrons un e-mail',
  submit: 'Soumettre',
  orderHistory: 'Historique des commandes',
  goBack: 'Retourner',
  loginToViewOrdDet: "Entrez l'e-mail et le numéro de commande pour afficher tous les détails de la commande",
  trackOrderStatus: "Suivre l'état de la commande",
  ordNum: 'Numéro de commande',
  magicLink: 'Lien magique',
  connectWith: 'Continuer avec {0}',
  bestSellers: 'Meilleures ventes',
  or: 'ou',
  continue: 'Continuer',
  tracking: 'suivi',
  shipActivity: "Activité d'expédition",
  couActivity: 'Porteur de contact',
  feedbackTitle: 'DONNEZ VOTRE AVIS',
  feedbackDesc: 'Évaluez votre expérience de livraison',
  satisfied: 'Satisfait',
  notSatisfied: 'Pas satisfait',
  shipTitle: ' Votre commande arrivera dans plusieurs envois',
  shipment: 'Shipment',
  hideDesc: "cacher toutes les activités d'expédition",
  showDesc: "Voir toutes les activités d'expédition",
  domainError: 'Domaine introuvable',
  estimate: 'DATE ESTIMÉE',
  serviceError: 'Service indisponible',
  feedbackSuccess: ' Feedback soumis avec succès, merci',
  no_tracking_details: ' Aucun détail de suivi disponible',
  domain_not_exist: 'Domaine introuvable',
  invalid_tracking_number: 'Numéro de suivi invalide',
  copied: 'Copié!',
  copy: 'Copie',
  webTrack: 'Site du suivi sur le transporteur',
  backTrack: 'Retour au site',
  trackAt: 'Suivez votre colis à',
  instead: 'au lieu',
  moreSee: 'Il y a plus à voir',
  feedbackSuccessTitle: 'JE VOUS REMERCIE',
  feedbackSuccessDesc: 'Vos commentaires nous aident à améliorer notre service de livraison',
  delivered: 'Livré',
  shipped: 'Expédié',
  orderPlaced: 'Commande passée',
  addComment: 'Ajoutez des commentaires',
  smsNotify: 'Notification SMS',
  enterPhoneNumber: 'Veuillez entrer votre numéro de téléphone',
  phoneLimitError: 'Le numéro de téléphone est trop court ou contient des caractères invalides.',
  smsActiveMsg: "Vous recevrez un SMS lorsque l'envoi sera mis à jour",
  trackingNumber: 'Numéro de suivi',
  sendFeedback: 'Envoyer des commentaires',
  addAComment: 'Ajouter un commentaire (facultatif)',
  contactUs: 'Contactez-nous',
  terms: 'termes',
  privacy: 'Intimité',
  getUpdates: 'Obtenir les mises à jour',
  trackWtihNumDesc: "Vérifiez l'état de votre envoi en saisissant votre numéro de suivi",
  enterValidTrackNo: "Entrez un numéro de suivi valide",
  noInfoAvailTrack: "Vérifiez l'état de votre envoi en saisissant votre numéro de suivi",
  track: "Suivre",
  trackNum: "Numéro de suivi",
};

export default localizeObject;