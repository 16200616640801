import React, { useState, useContext, useEffect } from 'react';
import {useStyletron} from 'baseui';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../../context/app-context';

import Localization from '../../localization';
import Constants from '../../lib/Constants';

import T5TfCard from '../UI/T5TfCard';
import Header from '../Header';
import TfToastContainer from '../UI/TfToastContainer';

import HappySelIcon from '../../images/happy_selected.svg';
import HappyIcon from '../../images/happy_smily.svg';
import SadSelIcon from '../../images/sad_selected.svg';
import SadIcon from '../../images/sad_smily.svg';
import DoneIcon from '../../images/ico-general-done@3x.svg';
import CarrierIcon from '../../images/ico-carrier-info.svg';
import CarrierPhone from '../../images/ico-carrier-phone.svg';
import CarrierLink from '../../images/ico-carrier-link.svg';

import { Button } from "baseui/button";
import {FormControl} from 'baseui/form-control';
import { Textarea } from "baseui/textarea";
import { PhoneInput } from "baseui/phone-input";
import { Avatar } from "baseui/avatar";
import { KIND as ToastKind } from "baseui/toast";

const Theme5 = (props) => {
  const appContext = useContext(AppContextData);
  const [css] = useStyletron();
  const toastRef = React.useRef(null);
  const [activeId, setActiveId] = useState('0');
  const [activeTab, setActiveTab] = useState('ship');
  const [trackDetails, setTrackDetails] = useState(props.trackDetails);
  const [trackValues, setTrackValues] = useState(props.trackValues);
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedbackId, setFeedbackId] = useState('');
  const [comments, setComments] = useState('');
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const [country, setCountry] = React.useState({
    id: 'US',
    dialCode: '+1',
    label: 'United States'
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidLoginPhone, setIsValidLoginPhone] = useState(true);
  const showLoginPhoneError = !isValidLoginPhone;
  const [phoneLoginErrorMsg, setPhoneLoginErrorMsg] = useState('');
  const loginPhoneRef = React.useRef(null);
  const [isPhoneUpdating, setIsPhoneUpdating] = useState(false);
  const [smsSuccess, setSmsSuccess] = useState(false);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  useEffect(() => {
    let trackingItems = props.pageDetails.trackingNumbers.split(',');
    if (props.tfTheme.prdRecommend) {
      axios.get(Constants.TRACKFREE.APIURL + sd + '/recommended-products/' + trackingItems[0])
      .then(response => {
        if (response.data.status === 'success') {
          setRecommendedProducts(response.data.recommendedProducts);
        }
      }).catch(error => {
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createShip = () => {
    let trackingItems = props.pageDetails.trackingNumbers.split(',');
    let fields = [];
    let j = 0;
    for (let i in trackingItems) {
      j++;
      fields.push(<span key={i} style={{padding: '0 10px'}}>
        <span
          className={activeId === i ? 'store_multi_label_item store_multi_active' : 'store_multi_label_item'}
          onClick={()=>{setActiveId(i); onShowTracking(i)}}>
          {loc.shipment} {j}
        </span>
      </span>);
    }
    return fields;
  }

  const onShowTracking = (key) => {
    props.passLoaded(false);
    let trackingItems = props.pageDetails.trackingNumbers.split(',');
    setTrackDetails(props.trackingData[trackingItems[key]]);
    setTrackValues(props.trackingData[trackingItems[key]].trackDetails.trackValues);

    if (props.trackingData[trackingItems[key]].trackDetails.error) {
      props.passError(props.trackingData[trackingItems[key]].trackDetails.error, props.trackingData[trackingItems[key]]);
    }
    props.passLoaded(true);
  }

  const buildContactActivity = (activity) => {
    return (
      <div className="tk-bd-cr" style={{fontFamily: props.tfTheme.secondaryTextFont}}>
        <div className="tk-st-ct">
          <div className="tk-cr-ic">
          <img src={CarrierIcon} alt="" width='32' height='32' />
          </div>
          <div className="tk-cr-da">{trackDetails.courierCaption}</div>
        </div>
        {activity.phoneNumber ?
          <div className="tk-st-ct">
            <div className="tk-cr-ic">
              <img src={CarrierPhone} alt="" width='32' height='32' />
            </div>
            <div className="tk-cr-da">{activity.phoneNumber}</div>
          </div>
        : null}
        {activity.website ?
          <div className="tk-st-ct">
            <div className="tk-cr-ic">
              <img src={CarrierLink} alt="" width='32' height='32' />
            </div>
            <div className="tk-cr-da">
              <a
                className="tk-wb-ur"
                href={activity.trackUrl}
                target='_blank'
                rel="noreferrer">{activity.website}</a></div>
          </div>
        : null}
      </div>
    )
  }

  function showTrackingDetails()
  {
    return (
      <div className="t5-tr-dt">
        <div>
          <div className="t5-es-st" style={{backgroundColor: props.tfTheme.containerBackground, borderBottomColor: props.tfTheme.borderLinesColor}}>
            <div
              className="t5-st-ss"
              // style={{color: props.tfTheme.primaryTextColor}}
              style={{fontFamily: props.tfTheme.secondaryTextFont}}
              >{trackDetails.shipmentTitle.status}</div>
            <div
              className="t5-dl-tm"
              // style={{color: props.tfTheme.primaryTextColor}}
              style={{fontFamily: props.tfTheme.primaryTextFont}}
              >{trackDetails.shipmentTitle.days}</div>
            <div
              className="t5-dl-tm"
              // style={{color: props.tfTheme.primaryTextColor}}
              style={{fontFamily: props.tfTheme.primaryTextFont}}
              >{trackDetails.shipmentTitle.date}</div>
          </div>
        </div>
        <div className="t5-ct-st" style={{backgroundColor: props.tfTheme.containerBackground}}>
          <div>
            <div className="st-op-tb">
              <div
                onClick={()=>setActiveTab('ship')}
                className={activeTab === 'ship' ? 'store_activity_label_item store_multi_active' : 'store_activity_label_item'} style={{fontFamily: props.tfTheme.secondaryTextFont}}>{loc.shipActivity}</div>
              <div
                onClick={()=>setActiveTab('carrier')}
                className={activeTab === 'carrier' ? 'store_activity_label_item store_multi_active' : 'store_activity_label_item'} style={{fontFamily: props.tfTheme.secondaryTextFont}}>{loc.couActivity}</div>
            </div>
            {activeTab === 'ship' && trackValues ?
              <div>
                <div className="t5-tk-st">
                  <div>
                  <Avatar
                    name={trackDetails.courierName}
                    size="scale1000"
                    src={trackDetails.courierLogo} />
                  </div>
                  <div className="st-tk-sn" style={{fontFamily: props.tfTheme.secondaryTextFont}}>
                    <div>
                      <div
                        className="store_tracking_title"
                        style={{color: props.tfTheme.secondaryTextColor}}>{loc.trackingNumber}</div>
                      <div
                        className="tf-tk-nm">{trackDetails.trackingNum}</div>
                    </div>
                  </div>
                </div>
                <div style={{marginTop: '32px'}}>
                  <ul className="tf-sp-ev">
                    {buildShippingActivity()}
                  </ul>
                </div>
              </div>
            :
              <div className='tk-ct-sn'>
                {buildContactActivity(trackDetails.carrierContact)}
              </div>
          }
          </div>
        </div>
        {props.tfTheme.feedbackWidget && trackDetails.feedback ?
          <div className="t5-st-fd" style={{borderTopColor: props.tfTheme.borderLinesColor}}>
            <div>
              {buildFeedback(trackDetails.feedbackData, trackDetails.shortCode)}
            </div>
          </div>
        : null
        }
        {trackDetails.smsNotify ?
          <div className="t5-st-fd" style={{borderTopColor: props.tfTheme.borderLinesColor}}>
            <div style={{textAlign: 'center'}}>
              <div style={{margin: '24px'}} className="store_feedback_desc">{loc.smsNotify}</div>
              {smsSuccess ?
                <div>
                  <div><img src={DoneIcon} alt="" /></div>
                  <p className="store_feedback_success_desc">{loc.smsActiveMsg}</p>
                </div>
              :
                <div>
                  <FormControl
                    error={
                      showLoginPhoneError ? phoneLoginErrorMsg : null}>
                    <PhoneInput
                      error={showLoginPhoneError}
                      inputRef={loginPhoneRef}
                      onBlur={()=>onLoginPhoneValidate()}
                      country={country}
                      autoComplete="off"
                      onCountryChange={({ option }) => setCountry(option)}
                      text={phoneNumber}
                      onTextChange={e => setPhoneNumber(e.currentTarget.value)}
                      overrides={{
                        CountrySelectContainer: {
                          style: () => ({
                            backgroundColor: "#FFFFFF",
                          })
                        },
                        Input: {
                          props: {
                            overrides: {
                              Root: {
                                style: () => ({
                                  height: '60px',
                                  borderTopLeftRadius: '4px',
                                  borderTopRightRadius: '4px',
                                  borderBottomLeftRadius: '4px',
                                  borderBottomRightRadius: '4px',
                                }),
                              },
                              Input: {
                                style: () => ({
                                  backgroundColor: "#FFFFFF",
                                })
                              }
                            }
                          }
                        }
                      }}
                    />
                  </FormControl>
                  <Button
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: '100%',
                          color: props.tfTheme.primaryBtnTextColor,
                          backgroundColor: props.tfTheme.primaryBtnColor,
                          fontFamily: "'Poppins', sans-serif",
                          ':hover': {
                            backgroundColor: props.tfTheme.primaryBtnColor,
                          }
                        })
                      }
                    }}
                    disabled={isPhoneUpdating ? {} : ''}
                    isLoading={isPhoneUpdating ? {} : ''}
                    onClick={()=>onCheckPhoneLogin(trackDetails.shortCode)}>{loc.getUpdates}</Button>
                </div>
              }
            </div>
          </div>
        : null
        }
      </div>
    );
  }

  function buildShippingActivity() {
    let shipFields = [];
    trackValues.map((item, i, {length}) => {
      let dateFields = item.dateTime.split(' ');
      shipFields.push(<li key={i}>
        <span className={item.statusCode === "delivered" ? "tf-sp-dd" : "tf-sp-tm"}>
          <span className="store_activity_table_activity">{`${dateFields[0]} ${dateFields[1]} ${dateFields[2]}`}</span>
          <span className="store_activity_table_dateTime">{`${dateFields[3] ? dateFields[3] : ''} ${dateFields[4] ? dateFields[4] : ''}`}</span>
        </span>
        <span className={i + 1 === length ? 'tf-sp-sf' : 'tf-sp-sn'}>
          <span className="store_activity_table_activity">
            <div dangerouslySetInnerHTML={{ __html: item.statusDescription }} />
          </span>
          <span className="store_activity_table_activity">{`${item.city}`}</span>
        </span>
      </li>);
      return item;
    });
    return shipFields;
  }

  const buildFeedback = (feedback, shortCode) => {
    return (<div>
        {isFeedback ? <div style={{textAlign: 'center', marginTop: '24px'}}>
          <div><img src={DoneIcon} alt="" /></div>
          <p className="store_feedback_success_title">{loc.feedbackSuccessTitle}!</p>
          <p className="store_feedback_success_desc">{loc.feedbackSuccessDesc}</p>
        </div>:<div>
        <div
          className="store_feedback_desc"
          // style={{color: tfTheme.secondaryTextColor}}
          style={{fontFamily: props.tfTheme.secondaryTextFont, marginTop: '24px'}}
          >{loc.feedbackDesc}</div>
        <div className={css({display: 'flex', alignItems: 'center', justifyContent: 'center'})}>
          <div className="fb-details">
            <div
              className="store_feedback_tile_happy"
              onClick={()=>{
                setFeedbackStatus('happy');
                updateFeedback(feedback.happy)
              }}>
              <img
                src={feedbackStatus === 'happy' ? HappySelIcon : HappyIcon}
                height="48"
                width="48"
                alt="" />
            </div>
          </div>
          <div className="fb-details">
            <div
              className="store_feedback_tile_sad"
              onClick={()=>{
                setFeedbackStatus('sad');
                updateFeedback(feedback.unhappy)
              }}>
              <img
                src={feedbackStatus === 'sad' ? SadSelIcon : SadIcon}
                height="48"
                width="48"
                alt="" />
            </div>
          </div>
        </div>
        {feedbackId ?
          <div style={{paddingBottom: '16px', textAlign: 'center'}}>
            <div className='tf-cm-sc'>
            <Textarea
              value={comments}
              onChange={e => setComments(e.target.value)}
              placeholder={loc.addAComment}
              maxLength="500"
              overrides={{
                InputContainer: {
                  style: () => ({ backgroundColor: "#FFFFFF" }),
                }
              }} />
            </div>
            <div className='tf-cm-sc' style={{marginTop: '16px'}}>
              <Button
                overrides={{
                  BaseButton: {
                    style: () => ({
                      width: '100%',
                      color: props.tfTheme.primaryBtnTextColor,
                      backgroundColor: props.tfTheme.primaryBtnColor,
                      fontFamily: "'Open Sans', sans-serif",
                      ':hover': {
                        backgroundColor: props.tfTheme.primaryBtnColor,
                      }
                    })
                  }
                }}
                onClick={()=>onSubmitComment(shortCode)}>
                {loc.sendFeedback}
              </Button>
            </div>
          </div>
          : null
          }
        </div>}
    </div>)
  }

  const buildRecommendation = (prods) => {
    let fields = [];

    function updateRecomPrdts(id){
      let tf_source = localStorage.getItem('tf_source');
      axios.get(`${Constants.TRACKFREE.APIURL}rec-prd-clk/${id}?source=${tf_source}`);
    }

    prods.forEach((prod, i) => {
      fields.push(<div key={i} style={{marginTop:'5px'}}>
      <div>{buildProducts(prod[i])}</div>
      <div>{buildProducts(prods[i])}</div>
      </div>)
    });
    return fields;

    function buildProducts(prod) {
      if (prod) {
        let price = parseInt(prod.price);
        return (<div
          className="t5-rp-it"
          onClick={()=>{window.open(prod.handle, '_blank'); updateRecomPrdts(prod.id)}}>
          <div
            className='t5-rp-im'
            style={{borderColor: props.tfTheme.prdThumbBorderColor}}
          >
          <img
            src={prod.image}
            width='150'
            height='150'
            style={{objectFit: 'cover'}}
            alt={prod.productAltImgTag} />
          </div>
          <div className="tk-rp-pn" style={{fontFamily: props.tfTheme.secondaryTextFont}}>{prod.name}</div>
          <div className="tk-rp-pr" style={{fontFamily: props.tfTheme.secondaryTextFont}}>{price.toLocaleString('en-US', {style: 'currency', currency:prod.currency, minimumFractionDigits: 0})}</div>
        </div>)
      } else {
        return(<div></div>);
      }
    }
  }

  const onLoginPhoneValidate = () => {
    setPhoneNumber(phoneNumber.replace(/[^0-9]/g, ''))
  }

  const onCheckPhoneLogin = (shortCode) => {
    if (phoneNumber === '') {
      setIsValidLoginPhone(false);
      loginPhoneRef.current && loginPhoneRef.current.focus();
      setPhoneLoginErrorMsg(loc.enterPhoneNumber);
      return false;
    } else if (phoneNumber.length <= 5) {
      setIsValidLoginPhone(false);
      loginPhoneRef.current && loginPhoneRef.current.focus();
      setPhoneLoginErrorMsg(loc.phoneLimitError);
      return false;
    } else {
      setPhoneLoginErrorMsg('');
      setIsValidLoginPhone(true);
    }

    setIsPhoneUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + 'sms-notify-update',
    {
      tracking_code: shortCode,
      sms_country: country.id,
      sms_country_code: country.dialCode.replace('+', ''),
      sms_notify_number: phoneNumber
    })
    .then(response => {
      if (response.data.status === 'error') {
        toastRef.current.show({ children: response.data.message, kind: ToastKind.negative });
      } else {
        setSmsSuccess(true);
      }
      setIsPhoneUpdating(false);
    }).catch(error => {
    });
  }

  const updateFeedback = (id) => {
    setFeedbackId(id);
  }

  const onSubmitComment = (shortCode) => {
    setIsFeedback(true);
    axios.get(Constants.TRACKFREE.APIURL + 'feedback?fs=' + feedbackId);
    if (comments) {
      axios.post(Constants.TRACKFREE.APIURL + 'comments',
      {
        tracking_code: shortCode,
        comments: comments
      })
      .then(response => {
      }).catch(error => {
      });
    }
  }

  return (<div>
    {appContext.mainPageThemeId ?
      <Header data={appContext} />
    : null}
    {props.isMultiTracking ?
      <div style={{textAlign: 'center'}}>
        <div className="st_ml_tk">
          <div className="store_multi_label" style={{fontFamily: props.tfTheme.primaryTextFont}}>{loc.shipTitle}:</div>
          <div className="st_ml_it" style={{fontFamily: props.tfTheme.primaryTextFont}}>
            {createShip()}
          </div>
        </div>
      </div>
    : null
    }
    <div className="t4-mn-hd">
      <div className="row" style={{marginTop: '32px'}}>
        <div className={props.tfTheme.showBanner && props.pageDetails.banner ? "col2-8" : "col2-12" }>
          <T5TfCard bgColor={props.tfTheme.containerBackground}>
            {showTrackingDetails()}
          </T5TfCard>
        </div>
        {props.tfTheme.showBanner && props.pageDetails.banner ?
          <div className="col-4">
            <div className="t5-rg-br">
              {props.pageDetails.bannerLink1 ?
                <a href={props.pageDetails.bannerLink} target='_blank' rel="noreferrer">
                  <img
                    className="t5-bn-im"
                    src={props.pageDetails.banner}
                    alt={props.pageDetails.bannerAlt ? props.pageDetails.bannerAlt : ''} />
                </a>
              :
                <img
                  className="t5-bn-im"
                  src={props.pageDetails.banner}
                  alt={props.pageDetails.bannerAlt ? props.pageDetails.bannerAlt : ''} />
              }
            </div>
          </div>
        : null
        }
        {props.tfTheme.prdRecommend && recommendedProducts.length > 0 ?
          <div className="col2-12">
            <div className='t5-rc-ct'>
              <div className='t4-rc-pd'>
                {buildRecommendation(recommendedProducts)}
              </div>
            </div>
          </div>
        : null
        }
      </div>
    </div>
    <TfToastContainer ref={toastRef} />
  </div>);
}

export default Theme5;