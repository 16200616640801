import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AppContextData } from '../../context/app-context';
import { useParams } from 'react-router-dom';

import Constants from '../../lib/Constants';
import Localization from '../../localization';
import '../../css/Main.css';
import OrderComponent from './OrderComponent';
import Header from '../Header';
import Footer from '../Footer';
import TfCard from '../UI/TfCard';

import {Skeleton} from 'baseui/skeleton';
import {Notification, KIND as NotificationKind} from 'baseui/notification';

const Orders = () => {
  const appContext = useContext(AppContextData);
  const { uniqueLink } = useParams();
  const [isOrderLoaded, setIsOrderLoaded] = useState(false);
  const [isInvalidOrders, setIsInvalidOrders] = useState('');
  const [orders, setOrders] = useState([]);

  const [orderDetailLoaded, setOrderDetailLoaded] = useState(false);
  const [isOrderDetailView, setOrderDetailView] = useState(false);
  const [isInvalidOrder, setIsInvalidOrder] = useState('');
  const [order, setOrder] = useState([]);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  if (appContext.orderLookupPage === false) {
    if (appContext.storeUrl) {
      window.location.href=appContext.storeUrl;
    } else {
      window.location.href='https://trackfree.io/';
    }
  }

  useEffect(() => {
    axios.get(Constants.TRACKFREE.APIURL + sd + '/store-orders/' + uniqueLink)
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidOrders(response.data.message);
      } else {
        setOrders(response.data.orders);
      }
      setIsOrderLoaded(true);
    }).catch(error => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildDefaultActivity = () => {
    return (
      <div>
        <div>
          <Skeleton
            width="75%"
            height="25px" />
        </div>
        <div style={{margin: '16px 0'}}>
          <Skeleton
            width="50%"
            height="25px" />
        </div>
        <div>
          <Skeleton
            width="25%"
            height="25px" />
        </div>
      </div>
    );
  }

  const onShowOrderDetails = (shortCode) => {
    setOrderDetailView(true);
    setOrderDetailLoaded(false);
    axios.get(Constants.TRACKFREE.APIURL + sd + '/store-order/' + shortCode)
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidOrder(response.data.message);
      } else {
        setOrder(response.data.orderData);
      }
      setOrderDetailLoaded(true);
    }).catch(error => {
    });
  }

  let orderDetailsData = '';
  if (appContext.mainPageThemeId && appContext.orderLookupPage) {
    orderDetailsData = <div className="storefront_body" style={{backgroundColor: appContext.styles.bodyBackground}}>
      <Header data={appContext} />
      {isOrderDetailView ?
        <div className="tf-mn2-hd">
          <TfCard bgColor={appContext.styles.containerBackground}>
            {orderDetailLoaded ?
              <div>
                {isInvalidOrder ?
                  <div style={{margin: '16px'}}>
                    <Notification
                      kind={NotificationKind.negative}
                      overrides={{Body: {style: {width: 'auto'}}}}>{isInvalidOrder}</Notification>
                  </div>
                :
                  <div>
                    <div
                    className="tf-link"
                    style={{fontFamily: appContext.styles.secondaryTextFont}}
                    onClick={()=>setOrderDetailView(false)}>{loc.goBack}</div>
                    <OrderComponent order={order} orderRestricted={false} />
                  </div>
                }
              </div>
            : <div style={{margin: '16px'}}>
                {buildDefaultActivity()}
              </div>
            }
          </TfCard>
        </div>
      :
        <div className="tf-mn2-hd">
          <TfCard bgColor={appContext.styles.containerBackground}>
            {isOrderLoaded ?
              <div>
                {isInvalidOrders ?
                  <div style={{margin: '16px'}}>
                    <Notification
                      kind={NotificationKind.negative}
                      overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isInvalidOrders}</Notification>
                  </div>
                : 
                  <div>
                    <div className="ord-cmp-ttl" style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.orderHistory}</div>
                    <div style={{textAlign: 'left'}}>
                      <table className="ord-itm-lst" style={{fontFamily: appContext.styles.secondaryTextFont, fontSize: '14px'}}>
                        <tbody>
                          {orders.map((order, i) => (
                            <tr
                              key={i}
                              className="od-it-ls"
                              style={{cursor: 'pointer'}}
                              onClick={()=>onShowOrderDetails(order.shortCode)}>
                              <td className="tf-link">{order.orderNumber}</td>
                              <td>{order.orderDate}</td>
                              <td>{order.orderStatus}</td>
                              <td align="right">{getSymbolFromCurrency(order.orderCurrency ? order.orderCurrency : 'USD')}{order.orderTotal}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </div>
            : <div className="ord-lst-ldg">
                {buildDefaultActivity()}
              </div>
            }
          </TfCard>
        </div>
      }
      <Footer
        data={appContext.footerSettings}
        styles={appContext.styles} />
    </div>
  }

  return (<div>
    {orderDetailsData}
  </div>);
}

export default Orders;