import React, { useState, useContext } from 'react';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../context/app-context';
import { Select, SIZE } from "baseui/select";
import { useNavigate } from 'react-router-dom';

import FbIcon from '../images/fb.svg';
import TwitterIcon from '../images/twitter.svg';

import Localization from '../localization';

function Footer(props) {
  const appContext = useContext(AppContextData);
  const navigate = useNavigate();
  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  const languages = {
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano'
  };
  const [selectedLang, setSelectedLang] = useState([{ label: languages[lng], id: lng }]);

  function onSetLang(val) {
    setSelectedLang(val);
    localStorage.setItem('tf_lang', val[0].id);
    window.location.reload();
  }

  function gobackToHomePage()
  {
    navigate(`/`, { state: { from: 'tracking' } });
  }

  return (
    <div
      className="nt-ft-ct"
      style={{
        backgroundColor: props.styles.headerBackground
      }}>
      <div className="tf-mn2-hd">
        <div className="row">
          <div className="col2-12">
            <div className="nt-ft-dt">
              {props.cameFromHomePage ?
                <div style={{marginRight: '16px'}}>
                  <div
                    className="tf-link"
                    style={{fontFamily: appContext.styles.secondaryTextFont, fontSize: '0.825rem'}}
                    onClick={()=>gobackToHomePage()}>{loc.goBack}</div>
                </div>
              : null
              }
              {appContext.lang !== 'en' ?
                <div style={{marginRight: '16px'}}>
                  <div>
                    <Select
                      size={SIZE.mini}
                      options={[
                        { label: languages[appContext.lang], id: appContext.lang },
                        { label: languages['en'], id: "en" },
                      ]}
                      value={selectedLang}
                      clearable={false}
                      searchable={false}
                      onChange={params => onSetLang(params.value)}/>
                  </div>
                </div>
              : null
              }
              <div className="footer-title">
                {props.data.showFacebook ?
                  <img
                    src={FbIcon}
                    alt=""
                    style={{cursor: 'pointer'}}
                    onClick={()=>window.open(props.data.facebookLink)} />
                : null}
                {props.data.showTwitter ?
                  <img
                    src={TwitterIcon}
                    alt=""
                    style={{marginLeft: '32px', cursor: 'pointer'}}
                    onClick={()=>window.open(props.data.twitterLink)} />
                : null}
              </div>
              <div className="nt-tm-pv">
                {props.data.showContactUs ?
                  <span
                    className="nt-ft-lv"
                    style={{fontFamily: props.styles.headerFont}}
                    onClick={()=>window.open(props.data.contactUsLink)}>{loc.contactUs}</span>
                : null}
                {props.data.showTerms ?
                  <span
                    className="nt-ft-lv"
                    style={{fontFamily: props.styles.headerFont}}
                    onClick={()=>window.open(props.data.termsLink)}>{loc.terms}</span>
                : null}
                {props.data.showPrivacy ?
                  <span
                    className="nt-ft-lv"
                    style={{fontFamily: props.styles.headerFont}}
                    onClick={()=>window.open(props.data.privacyLink)}>{loc.privacy}</span>
                : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;