import React from 'react';
import { Card } from "baseui/card";

function TfCard(props)  {
  return (
    <Card
      overrides={{
        Root: {
          style: {
            backgroundColor: props.bgColor ? props.bgColor : '#ffffff',
            borderTopWidth: '1px',
            borderRightWidth: '1px',
            borderBottomWidth: '1px',
            borderLeftWidth: '1px',
            borderTopStyle: 'solid',
            borderRightStyle: 'solid',
            borderBottomStyle: 'solid',
            borderLeftStyle: 'solid',
            borderTopColor: '#e1e7eb',
            borderRightColor: '#e1e7eb',
            borderBottomColor: '#e1e7eb',
            borderLeftColor: '#e1e7eb',
            padding: '8px 0',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          }
        }
      }}
    >{props.children}</Card>
  )
}

export default TfCard;