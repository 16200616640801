import React from 'react';
import { ToasterContainer, PLACEMENT } from "baseui/toast";

export default React.forwardRef((props, ref) => {
  return (
    <ToasterContainer
      overrides={{
        ToastBody: {
          style: () => {
            return {
              fontSize: '16px',
                minWidth: '300px'
            };
          }
        }
      }}
      ref={ref}
      placement={PLACEMENT.bottom}
      autoHideDuration={3000} />
  )
});