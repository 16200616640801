import React from 'react';

function Header(props) {
  return (
    <div
      className={props.data.themeId === 5 ? "t5-st-hd" : "storeHeader"}
      style={{
        backgroundColor: props.data.styles.headerBackground,
        justifyContent:  props.data.styles.headerLogoPosition
      }}>
      <a
        style={{textDecoration: 'none', color: props.data.styles.headerColor ? props.data.styles.headerColor : 'black', fontFamily: props.data.styles.headerFont}}
        href={props.data.storeUrl}
        target="_blank"
        rel="noreferrer">
        {props.data.storeLogo === '' ? <p className="store_headertitle">{props.data.storeName}</p> : <img src={props.data.storeLogo} style={{maxHeight: '75px'}} alt=''></img>}
      </a>
    </div>
  );
}

export default Header;