import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import {useStyletron} from 'baseui';
import { AppContextData } from '../../context/app-context';

import Localization from '../../localization';
import Constants from '../../lib/Constants';

import Styles from '../../lib/Sytles';
import TfCard from '../UI/TfCard';

import {FormControl} from 'baseui/form-control';
import {Input, SIZE as InputSize} from 'baseui/input';
import { Button, SIZE as ButtonSize } from "baseui/button";
import {Notification, KIND as NotificationKind} from 'baseui/notification';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import {ChevronRight} from 'baseui/icon';
import {ChevronLeft} from 'baseui/icon';

const MainPage2 = (props) => {
  const appContext = useContext(AppContextData);
  const [css] = useStyletron();
  const [activeKey, setActiveKey] = React.useState("0");
  const [orderNumber, setOrderNumber] = useState('');
  const [orderEmail, setOrderEmail] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isInvalidOrder, setIsInvalidOrder] = useState(false);
  const [isValidOrderNumber, setIsValidOrderNumber] = useState(true);
  const showOrderNumberError = !isValidOrderNumber;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const showEmailError = !isValidEmail;

  const emailRef = React.useRef(null);
  const orderNumberRef = React.useRef(null);

  const [orderSendEmail, setOrderSendEmail] = useState('');
  const [isEmailOrderUpdating, setIsEmailOrderUpdating] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState('');
  const [isSuccessEmail, setIsSuccessEmail] = useState('');
  const [isValidSendEmail, setIsValidSendEmail] = useState(true);
  const showSendEmailError = !isValidSendEmail;

  const emailSendRef = React.useRef(null);

  const [products, setProducts] = useState([]);
  const [prevProducts, setPrevProducts] = useState(false);
  const [nextProducts, setNextProducts] = useState(false);
  const [banners, setBanners] = useState([]);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  let url = window.location.href;
  let subdomain = url.split('/')[2];
  const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;

  useEffect(() => {
    axios.get(Constants.TRACKFREE.APIURL + sd + '/rec-products')
    .then(response => {
      if (response.data.status === 'success') {
        setProducts(response.data.products);
        setNextProducts(response.data.products.length > 5 ? true : false);
      }
    }).catch(error => {
    });

    axios.get(Constants.TRACKFREE.APIURL + sd + '/main-page-banners')
    .then(response => {
      if (response.data.status === 'success') {
        setBanners(response.data.banners);
      }
    }).catch(error => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckOrder = () => {
    setIsInvalidOrder(false);
    if (!orderNumber) {
      setIsValidOrderNumber(false);
      orderNumberRef.current && orderNumberRef.current.focus();
      return false;
    } else {
      setIsValidOrderNumber(true);
    }

    var validator = require("email-validator");
    if (validator.validate(orderEmail) === false) {
      setIsValidEmail(false);
      emailRef.current && emailRef.current.focus();
      return false;
    } else {
      setIsValidEmail(true);
    }
    setIsUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + sd + '/get-order-code',
    {
      order_id: orderNumber,
      email: orderEmail
    })
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidOrder(true);
      } else {
        window.location.href=`/order-detail/${response.data.orderCode}`;
      }
      setIsUpdating(false);
    }).catch(error => {
    });
  }

  const onSendEmailLink = () => {
    setIsInvalidEmail('');
    setIsSuccessEmail('');
    var validator = require("email-validator");
    if (validator.validate(orderSendEmail) === false) {
      setIsValidSendEmail(false);
      emailSendRef.current && emailSendRef.current.focus();
      return false;
    } else {
      setIsValidSendEmail(true);
    }
    setIsEmailOrderUpdating(true);
    axios.post(Constants.TRACKFREE.APIURL + sd + '/store-order-status',
    {
      email: orderSendEmail
    })
    .then(response => {
      if (response.data.status === 'error') {
        setIsInvalidEmail(response.data.message);
      } else {
        setIsSuccessEmail(response.data.message);
      }
      setIsEmailOrderUpdating(false);
    }).catch(error => {
    });
  }

  const buildRecommendation = (pds) => {
    let fields = [];
    let prods = pds;
    if (nextProducts) {
      prods = pds.slice(0, 5)
    }
    if (prevProducts) {
      prods = pds.slice(-5)
    }

    function updateRecomPrdts(id) {
      let tf_source = localStorage.getItem('tf_source');
      axios.get(`${Constants.TRACKFREE.APIURL}rec-prd-clk/${id}?source=${tf_source}`);
    }
    
    if (prevProducts) {
      <div key="prev">
        <ChevronLeft
          size={42}
          title="Previous products"
          onClick={()=>{setNextProducts(true);setPrevProducts(false)}} />
      </div>
    }

    for (let i = 0; i < prods.length; i++) {
      fields.push(<div key={i} className={css({display: 'flex', alignItems: 'center', justifyContent: 'center'})} style={{marginTop:'5px'}}>
        <div className="nt-rc-dt">{buildProducts(prods[i])}</div>
      </div>);
    }

    if (nextProducts) {
      fields.push(
        <div key="next">
          <ChevronRight
            size={42}
            title="Next products"
            onClick={()=>{setNextProducts(false);setPrevProducts(true)}} />
        </div>
      )
    }
    return fields;

    function buildProducts(prod) {
      if (prod) {
        let price = parseInt(prod.price);

        return (<div
          className="store_recommend_item"
          onClick={()=>{window.open(prod.handle, '_blank'); updateRecomPrdts(prod.id)}}>
          <img style={{height:'auto', maxWidth:'100%', width:'auto'}} src={prod.image} alt="" />
          <div className="tk-rp-pn" style={{fontFamily: props.styles.secondaryTextFont}}>{prod.name}</div>
          <div className="tk-rp-pr" style={{fontFamily: props.styles.secondaryTextFont}}>{price.toLocaleString('en-US', {style: 'currency', currency:prod.currency, minimumFractionDigits: 0})}</div>
        </div>)
      } else {
        return(<div></div>);
      }
    }
  }

  return(
    <div className="tf-mn2-hd">
      <div className="tk-t2-ct">
        <TfCard bgColor={props.styles.containerBackground}>
          <div className="row" style={{margin: '32px 0'}}>
            <div className="col2-4">
              <div className="nt-ct-lt">
                <div className="nt-tk-os" style={{fontFamily: props.styles.primaryTextFont}}>{loc.trackOrderStatus}</div>
                <div style={{margin: '0 16px'}}>
                  <Tabs
                    activeKey={activeKey}
                    onChange={({ activeKey }) => {
                      setActiveKey(activeKey);
                    }}
                    fill={FILL.fixed}
                    activateOnFocus>
                    <Tab
                      title={loc.ordNum}
                      style={{fontFamily: props.styles.primaryTextFont}}>
                      <div>
                        <FormControl
                          label={loc.orderNumber}
                          overrides={Styles.FormLabel}
                          error={
                            showOrderNumberError ? loc.enterOrderNumber : null}>
                          <Input
                            autoComplete="off"
                            size={InputSize.large}
                            overrides={Styles.FormInput}
                            error={showOrderNumberError}
                            inputRef={orderNumberRef}
                            onChange={e => setOrderNumber(e.target.value)}
                            value={orderNumber} />
                        </FormControl>
                        <FormControl
                          label={loc.email}
                          overrides={Styles.FormLabel}
                          error={
                            showEmailError ? loc.enterValidEmail : null}>
                          <Input
                            autoComplete="off"
                            size={InputSize.large}
                            overrides={Styles.FormInput}
                            error={showEmailError}
                            onChange={e => setOrderEmail(e.target.value)}
                            value={orderEmail} />
                        </FormControl>
                        {isInvalidOrder ?
                          <div style={{margin: '16px 0'}}>
                            <Notification
                              kind={NotificationKind.negative}
                              overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{loc.orderNotFound}</Notification>
                          </div>
                        : null}
                        <Button
                          overrides={{
                            BaseButton: {
                              style: () => ({
                                width: '100%',
                                color: props.styles.primaryBtnTextColor,
                                backgroundColor: props.styles.primaryBtnColor,
                                fontFamily: "'Open Sans', sans-serif",
                                ':hover': {
                                  backgroundColor: props.styles.primaryBtnColor,
                                }
                              })
                            }
                          }}
                          size={ButtonSize.large}
                          disabled={isUpdating ? {} : ''}
                          isLoading={isUpdating ? {} : ''}
                          onClick={()=>onCheckOrder()}>{loc.orderLookup}</Button>
                      </div>
                    </Tab>
                    <Tab
                      title={loc.magicLink}
                      style={{fontFamily: props.styles.primaryTextFont}}
                      overrides={{
                        TabPanel: {
                          padding: '0px'
                        }
                      }}>
                      <div>
                        <FormControl
                          label={loc.email}
                          overrides={Styles.FormLabel}
                          error={
                            showSendEmailError ? loc.enterValidEmail : null}>
                          <Input
                            autoComplete="off"
                            size={InputSize.large}
                            overrides={Styles.FormInput}
                            error={showSendEmailError}
                            onChange={e => setOrderSendEmail(e.target.value)}
                            value={orderSendEmail} />
                        </FormControl>
                        {isInvalidEmail ?
                          <div style={{margin: '16px 0'}}>
                            <Notification
                              kind={NotificationKind.negative}
                              overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isInvalidEmail}</Notification>
                          </div>
                        : null}
                        {isSuccessEmail ?
                          <div style={{margin: '16px 0'}}>
                            <Notification
                              kind={NotificationKind.positive}
                              overrides={{Body: {style: {width: 'auto', fontFamily: appContext.styles.secondaryTextFont}}}}>{isSuccessEmail}</Notification>
                          </div>
                        : null}
                        <Button
                          overrides={{
                            BaseButton: {
                              style: () => ({
                                width: '100%',
                                color: props.styles.primaryBtnTextColor,
                                backgroundColor: props.styles.primaryBtnColor,
                                fontFamily: "'Open Sans', sans-serif",
                                ':hover': {
                                  backgroundColor: props.styles.primaryBtnColor,
                                }
                              })
                            }
                          }}
                          size={ButtonSize.large}
                          disabled={isEmailOrderUpdating ? {} : ''}
                          isLoading={isEmailOrderUpdating ? {} : ''}
                          onClick={()=>onSendEmailLink()}>{loc.submit}</Button>
                      </div>
                      <div style={{marginTop: '32px'}}>
                        <Button
                          onClick={()=>window.location.href=`${Constants.TRACKFREE.DOMAIN_URL}sf-login/google/${appContext.st}`}
                          size={ButtonSize.large}
                          overrides={{
                            BaseButton: {
                              style: () => ({
                                width: '100%',
                                color: props.styles.primaryBtnTextColor,
                                backgroundColor: props.styles.primaryBtnColor,
                                fontFamily: "'Open Sans', sans-serif",
                                ':hover': {
                                  backgroundColor: props.styles.primaryBtnColor,
                                }
                              })
                            }
                          }}>{loc.formatString(loc.connectWith, 'Google')}</Button>
                      </div>
                      <div style={{marginTop: '16px'}}>
                        <Button
                          onClick={()=>window.location.href=`${Constants.TRACKFREE.DOMAIN_URL}sf-login/facebook/${appContext.st}`}
                          size={ButtonSize.large}
                          overrides={{
                            BaseButton: {
                              style: () => ({
                                width: '100%',
                                color: props.styles.primaryBtnTextColor,
                                backgroundColor: props.styles.primaryBtnColor,
                                fontFamily: "'Open Sans', sans-serif",
                                ':hover': {
                                  backgroundColor: props.styles.primaryBtnColor,
                                }
                              })
                            }
                          }}>{loc.formatString(loc.connectWith, 'Facebook')}</Button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="col2-4">
              <div className="nt-ct-lt">
                <img
                  src={banners.banner1}
                  alt=""
                  style={{cursor: 'pointer'}}
                  onClick={()=>window.open(props.data.bannerLink1)} />
              </div>
            </div>
            <div className="col2-4">
              <div className="nt-ct-lt">
                <img
                  src={banners.banner2}
                  alt=""
                  style={{cursor: 'pointer'}}
                  onClick={()=>window.open(props.data.bannerLink2)} />
              </div>
            </div>
          </div>
          {products.length ?
            <div className="row" style={{margin: '64px 0'}}>
              <div className="nt-bs-sl" style={{fontFamily: props.styles.primaryTextFont, marginBottom: '32px'}}>{loc.bestSellers}</div>
              <div className="nt-rc-ls">
                {buildRecommendation(products)}
              </div>
            </div>
          : null}
          <div className="row" style={{margin: '32px 0'}}>
            <div className="col2-4">
              <div className="nt-bn-ct">
                <img
                  src={banners.banner3}
                  alt=""
                  style={{cursor: 'pointer'}}
                  onClick={()=>window.open(props.data.bannerLink3)} />
              </div>
            </div>
            <div className="col2-4">
              <div className="nt-bn-ct">
                <img
                  src={banners.banner4}
                  alt=""
                  style={{cursor: 'pointer'}}
                  onClick={()=>window.open(props.data.bannerLink4)} />
              </div>
            </div>
            <div className="col2-4">
              <div className="nt-bn-ct">
                <img
                  src={banners.banner5}
                  alt=""
                  style={{cursor: 'pointer'}}
                  onClick={()=>window.open(props.data.bannerLink5)} />
              </div>
            </div>
          </div>
        </TfCard>
      </div>
    </div>
  );
}

export default MainPage2;