import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import WebFont from 'webfontloader';

import Constants from '../lib/Constants';

export const AppContextData = createContext([]);

export const AppContext = (props) => {
  const [storeData, setStoreData] = useState([]);

  useEffect(() => {
    let url = window.location.href;
    let subdomain = url.split('/')[2];
    const sd = subdomain !== 'localhost:3000' ? subdomain : Constants.TRACKFREE.LOCAL_SUBDOMAIN;
    
    axios.get(Constants.TRACKFREE.APIURL + sd + '/store-data')
    .then(response => {
      if (response.data.status === 'success') {
        if (response.data.storeData.favicon) {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = response.data.storeData.favicon;
        }
        if (response.data.storeData.lang !== 'en') {
          const lng = localStorage.getItem('tf_lang');
          const st = localStorage.getItem('tf_st');
          if (st === response.data.storeData.st) {
            if (!lng) {
              localStorage.setItem('tf_lang', response.data.storeData.lang);
            }
          } else {
            localStorage.setItem('tf_st', response.data.storeData.st);
            localStorage.setItem('tf_lang', response.data.storeData.lang);
          }
        } else {
          localStorage.setItem('tf_lang', 'en');
        }
        let googleFonts = [];
        googleFonts.push('Open Sans');
        if (response.data.storeData.styles.headerFontStyle) {
          googleFonts.push(response.data.storeData.styles.headerFontStyle);
        }
        if (response.data.storeData.styles.primaryTextFontStyle) {
          googleFonts.push(response.data.storeData.styles.primaryTextFontStyle);
        }
        if (response.data.storeData.styles.secondaryTextFontStyle) {
          googleFonts.push(response.data.storeData.styles.secondaryTextFontStyle);
        }
        if (googleFonts.length) {
          WebFont.load({
            google: {
              families: googleFonts
            }
          });
        }
        document.title = response.data.storeData.storeName ? response.data.storeData.storeName : 'TrackFree';
        const st = {
          ...response.data.storeData
        }
        setStoreData(st);
      } else {
        window.location.href = 'https://trackfree.io';
      }
    })
    .catch(error => {
    });
  }, []);

  return (
    <AppContextData.Provider value={storeData}>
      {props.children}
    </AppContextData.Provider>
  );
};

export default AppContext;