const localizeObject = {
  trackOrder: 'Rintraccia il tuo ordine',
  trakOrderDesc: "Inserisci il numero d'ordine e l'indirizzo email utilizzato per l'ordine.",
  orderNumber: "Numero d'ordine",
  enterOrderNumber: "Inserisci il tuo numero d'ordine",
  email: 'E-mail',
  enterValidEmail: 'Si prega di inserire un indirizzo email valido',
  orderLookup: 'Ordina cerca',
  orderNotFound: 'Ordine non trovato',
  order: 'Ordine',
  thankYou: 'Grazie',
  customerInfo: 'Informazioni per il cliente',
  contactInfo: 'Informazioni sui contatti',
  shippingAddress: 'Indirizzo di spedizione',
  shippingMethod: 'Metodo di spedizione',
  paymentMethod: 'Metodo di pagamento',
  orderStatus: "Lo stato dell'ordine",
  trakOrderEmailDesc: 'Inserisci la tua email per visualizzare tutti i tuoi ordini. Ti invieremo una mail',
  submit: 'Invia',
  orderHistory: 'Cronologia ordini',
  goBack: 'Torna indietro',
  loginToViewOrdDet: "Inserisci l'e-mail e il numero dell'ordine per visualizzare tutti i dettagli dell'ordine",
  trackOrderStatus: "Tieni traccia dello stato dell'ordine",
  ordNum: "Numero d'ordine",
  magicLink: 'Collegamento magico',
  connectWith: 'Continua con {0}',
  bestSellers: 'I più venduti',
  or: 'o',
  continue: 'Continua',
  tracking: 'Monitoraggio',
  shipActivity: 'Attività di spedizione',
  couActivity: 'Contatta il corriere',
  feedbackTitle: 'AIUTATECI A MIGLIORARE',
  feedbackDesc: 'Valuta la tua esperienza di spedizione',
  satisfied: 'Soddisfatto',
  notSatisfied: 'Non soddisfatto',
  shipTitle: 'Il tuo ordine arriverà in più spedizioni',
  shipment: 'Spedizione',
  hideDesc: 'Nascondi tutte le attività di spedizione',
  showDesc: 'Vedi tutte le attività di spedizione',
  domainError: 'Dominio non trovato',
  estimate: 'DATA STIMATA',
  serviceError: 'Servizio non disponibile',
  feedbackSuccess: 'Feedback inviato con successo, Grazie',
  no_tracking_details: 'Nessun dettaglio di tracciamento disponibile',
  domain_not_exist: 'Dominio non trovato',
  invalid_tracking_number: 'Numero di tracciamento non valido',
  copied: 'copiato!',
  copy: 'copia',
  webTrack: 'Traccia sul sito web del corriere',
  backTrack: 'Torna al sito web',
  trackAt: 'Traccia il tuo pacco su',
  instead: 'Invece',
  moreSee: "C'è altro da vedere",
  feedbackSuccessTitle: 'GRAZIE',
  feedbackSuccessDesc: 'Il tuo feedback aiuta a migliorare il nostro servizio di consegna',
  delivered: 'Consegnato',
  shipped: 'Spedito',
  orderPlaced: 'Ordine effettuato',
  addComment: 'Aggiungi commenti',
  smsNotify: 'Notifica SMS',
  enterPhoneNumber: 'Per favore inserisci il tuo numero di telefono',
  phoneLimitError: 'Il numero di telefono è troppo corto o contiene caratteri non validi.',
  smsActiveMsg: 'Riceverai un SMS quando la spedizione viene aggiornata',
  trackingNumber: 'Numero di identificazione',
  sendFeedback: 'Invia feedback',
  addAComment: 'Aggiungi un commento (facoltativo)',
  contactUs: 'Contattaci',
  terms: 'Termini',
  privacy: 'Privacy',
  getUpdates: 'Ottieni aggiornamenti',
  trackWtihNumDesc: "Controlla lo stato della spedizione inserendo il numero di tracciabilità",
  enterValidTrackNo: "Inserisci un numero di tracciabilità valido",
  noInfoAvailTrack: "Nessuna informazione disponibile per questo numero di tracciamento",
  track: "Traccia",
  trackNum: "Numero di identificazione",
};

export default localizeObject;