import React from 'react';
import { Card } from "baseui/card";

function T5TfCard(props)  {
  return (
    <Card
      overrides={{
        Root: {
          style: {
            backgroundColor: props.bgColor ? props.bgColor : '#ffffff',
            borderTopWidth: '1px',
            borderRightWidth: '1px',
            borderBottomWidth: '1px',
            borderLeftWidth: '1px',
            borderTopStyle: 'solid',
            borderRightStyle: 'solid',
            borderBottomStyle: 'solid',
            borderLeftStyle: 'solid',
            borderTopColor: '#E2D7D7',
            borderRightColor: '#E2D7D7',
            borderBottomColor: '#E2D7D7',
            borderLeftColor: '#E2D7D7',
            padding: '0',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            marginTop: '0',
          }
        },
        Contents: {
          style: () => ({
            marginTop: "0",
            marginRight: "0",
            marginBottom: "0",
            marginLeft: "0",
          })
        }
      }}
    >{props.children}</Card>
  )
}

export default T5TfCard;