import React, { useContext } from 'react';
import LocalizedStrings from 'react-localization';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AppContextData } from '../../context/app-context';

import Localization from '../../localization';
import '../../css/Main.css';

import {Skeleton} from 'baseui/skeleton';

const OrderComponent = (props) => {
  const appContext = useContext(AppContextData);
  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  return (<div className="ord-det-container">
    {props.orderRestricted === false ?
      <div style={{fontFamily: appContext.styles.primaryTextFont}}>
        <div>{loc.order} #{props.order.orderNum}</div>
        <div className="ord-tk-cs">{loc.thankYou} {props.order.customerName}!</div>
      </div>
    : null}
    <div style={{margin: '32px 0'}}>
      <table className="ord-itm-det" style={{fontFamily: appContext.styles.secondaryTextFont}}>
        <tbody>
          {props.order.orderItems.map((orderItem, i)=>(
            <tr key={i} className="od-it-ls">
              <td>
                {orderItem.imageUrl ?
                  <img
                    src={orderItem.imageUrl}
                    alt=""
                    width="64px" />
                : null
                }
              </td>
              <td>{orderItem.name}</td>
              <td>x</td>
              <td>{orderItem.quantity}</td>
              <td align="right">{getSymbolFromCurrency(props.order.orderCurrency ? props.order.orderCurrency : 'USD')}{orderItem.total}</td>
            </tr>
          ))}
          {props.order.orderTotals.map((orderTotal, i)=>(
            <tr key={i} className={orderTotal.title === 'Total' || orderTotal.title === 'Grand-Total' ? "ord-tot" : ''} >
              <td colSpan="4">{orderTotal.title}</td>
              <td align="right">{getSymbolFromCurrency(props.order.orderCurrency ? props.order.orderCurrency : 'USD')}{orderTotal.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div>
      <div className="ord-cmp-ttl"  style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.customerInfo}</div>
      {props.orderRestricted === false ?
        <div>
          <div className="ord-cnt-head" style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.contactInfo}</div>
          <div className="ord-cont-val" style={{fontFamily: appContext.styles.secondaryTextFont}}>{props.order.customerEmail}</div>
        </div>
      : null}
      <div className="tf-mt-16">
        <div className="ord-cnt-head" style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.shippingAddress}</div>
        {props.orderRestricted === false ?
        <div className="ord-cont-val" style={{fontFamily: appContext.styles.secondaryTextFont}}>
          <div>{props.order.shippingAddress.firstName} {props.order.shippingAddress.lastName}</div>
          <div>{props.order.shippingAddress.address1}</div>
          <div>{props.order.shippingAddress.address2}</div>
          <div>{props.order.shippingAddress.city} {props.order.shippingAddress.state} {props.order.shippingAddress.postcode}</div>
          <div>{props.order.shippingAddress.country}</div>
        </div>
        :
          <div style={{marginTop: '16px'}}>
            <div>
              <Skeleton
                width="30%"
                height="16px" />
            </div>
            <div style={{margin: '8px 0'}}>
              <Skeleton
                width="25%"
                height="16px" />
            </div>
            <div>
              <Skeleton
                width="20%"
                height="16px" />
            </div>
          </div>
        }
      </div>
      <div className="tf-mt-16">
        <div className="ord-cnt-head" style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.shippingMethod}</div>
        <div className="ord-cont-val" style={{fontFamily: appContext.styles.secondaryTextFont}}>{props.orderRestricted === false ?
          props.order.shippingMethod
        :
          <div>
            <Skeleton
              width="20%"
              height="16px" />
          </div>
        }</div>
      </div>
      <div className="tf-mt-16">
        <div className="ord-cnt-head" style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.paymentMethod}</div>
        <div className="ord-cont-val" style={{fontFamily: appContext.styles.secondaryTextFont}}>{props.orderRestricted === false ?
          props.order.paymentMethod
        :
          <div>
            <Skeleton
              width="20%"
              height="16px" />
          </div>
        }</div>
      </div>
      <div className="tf-mt-16">
        <div className="ord-cnt-head" style={{fontFamily: appContext.styles.primaryTextFont}}>{loc.orderStatus}</div>
        <div className="ord-cont-val" style={{fontFamily: appContext.styles.secondaryTextFont}}>{props.orderRestricted === false ?
         <div>
           {/* trackUrl */}
           {props.order.trackUrl ? <div className="tf-link" onClick={()=>window.open(props.order.trackUrl)}>{props.order.orderStatus}</div> : props.order.orderStatus}
         </div>
        :
          <div>
            <Skeleton
              width="20%"
              height="16px" />
          </div>
        }</div>
      </div>
    </div>
  </div>);
}

export default OrderComponent;