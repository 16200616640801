const localizeObject = {
  trackOrder: 'Verfolgen Sie Ihre Bestellung',
  trakOrderDesc: 'Geben Sie die für die Bestellung verwendete Bestellnummer und E-Mail-Adresse ein.',
  orderNumber: 'Bestellnummer',
  enterOrderNumber: 'Bitte geben Sie Ihre Bestellnummer ein',
  email: 'Email',
  enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  orderLookup: 'Bestellung nachschlagen',
  orderNotFound: 'Bestellung nicht gefunden',
  order: 'Befehl',
  thankYou: 'Dankeschön',
  customerInfo: 'Kundeninformation',
  contactInfo: 'Kontaktinformationen',
  shippingAddress: 'Lieferanschrift',
  shippingMethod: 'Versandart',
  paymentMethod: 'Bezahlverfahren',
  orderStatus: 'Bestellstatus',
  trakOrderEmailDesc: 'Geben Sie Ihre E-Mail-Adresse ein, um alle Ihre Bestellungen anzuzeigen. Wir senden Ihnen eine E-Mail',
  submit: 'einreichen',
  orderHistory: 'Bestellverlauf',
  goBack: 'Geh zurück',
  loginToViewOrdDet: 'Geben Sie E-Mail und Bestellnummer ein, um alle Bestelldetails anzuzeigen',
  trackOrderStatus: 'Bestellstatus verfolgen',
  ordNum: 'Bestellnummer',
  magicLink: 'Magischer Link',
  connectWith: 'Weiter mit {0}',
  bestSellers: 'Am besten Verkäufer',
  or: 'oder',
  continue: 'Weitermachen',
  tracking: ' Verfolgung',
  shipActivity: 'Versandaktivität',
  couActivity: 'Kontakt mit dem Beförderer',
  feedbackTitle: 'HILF UNS ZU VERBESSERN',
  feedbackDesc: 'Bewerten Sie Ihre Versanderfahrung',
  satisfied: 'Zufrieden',
  notSatisfied: 'Nicht zufrieden',
  shipTitle: 'Ihre Bestellung wird in mehreren Sendungen ankommen',
  shipment: 'Sendung',
  hideDesc: 'Alle Versandaktivitäten ausblenden',
  showDesc: 'Alle Versandaktivitäten anzeigen',
  domainError: 'Domäne nicht gefunden',
  estimate: 'GESCHÄTZTES DATUM',
  serviceError: 'Dienst nicht verfügbar',
  feedbackSuccess: 'Feedback erfolgreich gesendet, Danke',
  no_tracking_details: 'Keine Tracking-Details verfügbar',
  domain_not_exist: 'Domäne nicht gefunden',
  invalid_tracking_number: 'Ungültige Tracking-Nummer',
  copied: 'Kopiert!',
  copy: 'Kopieren',
  webTrack: 'Verfolgen Sie die Website des Carriers',
  backTrack: 'Zurück zur Website',
  trackAt: 'Verfolgen Sie Ihr Paket bei',
  instead: 'stattdessen',
  moreSee: 'Es gibt mehr zu sehen',
  feedbackSuccessTitle: 'DANKE',
  feedbackSuccessDesc: 'Ihr Feedback hilft uns, unseren Lieferservice zu verbessern',
  delivered: 'Delivered',
  shipped: 'Shipped',
  orderPlaced: 'Am besten',
  addComment: 'Verkäufer',
  smsNotify: 'SMS-Benachrichtigung',
  enterPhoneNumber: 'Bitte geben sie ihre Telefonnummer ein',
  phoneLimitError: 'Die Telefonnummer ist zu kurz oder enthält ungültige Zeichen.',
  smsActiveMsg: 'Sie erhalten eine SMS, wenn die Sendung aktualisiert wird',
  trackingNumber: 'Auftragsnummer',
  sendFeedback: 'Feedback abschicken',
  addAComment: 'Kommentar hinzufügen (optional)',
  contactUs: 'Kontaktiere uns',
  terms: 'Bedingungen',
  privacy: 'Privatsphäre',
  getUpdates: 'Updates bekommen',
  trackWtihNumDesc: "Überprüfen Sie den Status Ihrer Sendung, indem Sie Ihre Sendungsnummer eingeben",
  enterValidTrackNo: "Entrez un numéro de suivi valide",
  noInfoAvailTrack: "Aucune information disponible pour ce numéro de suivi",
  track: "Verfolgen",
  trackNum: "Auftragsnummer",
};

export default localizeObject;