const localizeObject = {
  trackOrder: 'Rastrea tu orden',
  trakOrderDesc: 'Ingrese el número de pedido y la dirección de correo electrónico utilizados para el pedido.',
  orderNumber: 'Número de orden',
  enterOrderNumber: 'Por favor ingrese su número de orden',
  email: 'Correo electrónico',
  enterValidEmail: 'Por favor, introduce una dirección de correo electrónico válida',
  orderLookup: 'Buscar orden',
  orderNotFound: 'Orden no encontrada',
  order: 'Pedido',
  thankYou: 'Gracias',
  customerInfo: 'Información al cliente',
  contactInfo: 'Información del contacto',
  shippingAddress: 'Dirección de Envío',
  shippingMethod: 'Método de envío',
  paymentMethod: 'Método de pago',
  orderStatus: 'Estado de la orden',
  trakOrderEmailDesc: 'Ingrese su correo electrónico para ver todos sus pedidos. Te enviaremos un correo electrónico',
  submit: 'Enviar',
  orderHistory: 'Historial de pedidos',
  goBack: 'Regresa',
  loginToViewOrdDet: 'Ingrese el correo electrónico y el número de pedido para ver todos los detalles del pedido',
  trackOrderStatus: 'Seguimiento del estado del pedido',
  ordNum: 'Número de orden',
  magicLink: 'Enlace mágico',
  connectWith: 'Continuar con {0}',
  bestSellers: 'Más vendidos',
  or: 'o',
  continue: 'Continuar',
  tracking: 'Rastreo',
  shipActivity: 'Actividad de envío',
  couActivity: 'Contactar con el operador',
  feedbackTitle: 'AYÚDANOS A MEJORAR',
  feedbackDesc: 'Califica tu experiencia de envío',
  satisfied: 'Satisfecho',
  notSatisfied: 'No satisfecho',
  shipTitle: 'Su pedido llegará en envíos múltiples',
  shipment: 'Envío',
  hideDesc: 'Ocultar toda la actividad de envío',
  showDesc: 'Ver toda la actividad de envío',
  domainError: 'Dominio no encontrado',
  estimate: 'FECHA ESTIMADA',
  serviceError: 'Servicio no disponible',
  feedbackSuccess: 'Comentarios enviados con éxito, gracias',
  no_tracking_details: 'No hay detalles de seguimiento disponibles',
  domain_not_exist: 'Dominio no encontrado',
  invalid_tracking_number: 'Número de seguimiento inválido',
  copied: 'Copiado!',
  copy: 'Dupdo',
  webTrack: 'Seguir en el sitio web del operador',
  backTrack: 'Volver al sitio web',
  trackAt: 'Rastrea tu paquete en',
  instead: 'en lugar',
  moreSee: 'Hay más para ver',
  feedbackSuccessTitle: 'GRACIAS',
  feedbackSuccessDesc: 'Sus comentarios ayudan a mejorar nuestro servicio de entrega',
  delivered: 'Entregado',
  shipped: 'Enviado',
  orderPlaced: 'Pedido realizado',
  addComment: 'Añadir comentarios',
  smsNotify: 'Notificación por SMS',
  enterPhoneNumber: 'Por favor, introduzca su número de teléfono',
  phoneLimitError: 'El número de teléfono es demasiado corto o contiene caracteres no válidos.',
  smsActiveMsg: 'Recibirás un SMS cuando se actualice el envío',
  trackingNumber: 'El número de rastreo',
  sendFeedback: 'Enviar comentarios',
  addAComment: 'Agregar un comentario (opcional)',
  contactUs: 'Contáctenos',
  terms: 'Condiciones',
  privacy: 'Intimidad',
  getUpdates: 'Obtener actualizaciones',
  trackWtihNumDesc: "Verifique el estado de su envío ingresando su número de seguimiento",
  enterValidTrackNo: "Ingrese un número de seguimiento válido",
  noInfoAvailTrack: "No hay información disponible para este número de seguimiento",
  track: "Seguir",
  trackNum: "El número de rastreo",
};

export default localizeObject;