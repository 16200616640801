module.exports = {
  FormLabel: {
    Label: {
      style: () => ({
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '16px'
      })
    }
  },
  FormInput: {
    Root: {
      style: ({$isFocused}) => {
        const borderColor = $isFocused ? 'black': '#B0B0B0';
        const borderWidth = $isFocused ? '2px': '1px';
        return {
          fontFamily: "'Open Sans', sans-serif",
          borderTopWidth: borderWidth,
          borderTopStyle: "solid",
          borderTopColor: borderColor,
          borderBottomWidth: borderWidth,
          borderBottomStyle: "solid",
          borderBottomColor: borderColor,
          borderLeftWidth: borderWidth,
          borderLeftStyle: "solid",
          borderLeftColor: borderColor,
          borderRightWidth: borderWidth,
          borderRightStyle: "solid",
          borderRightColor: borderColor,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        };
      }
    },
    Input: {
      style: () => ({
        backgroundColor: "#FFFFFF",
      })
    }
  },
}