import React, { useContext } from 'react';
import LocalizedStrings from 'react-localization';
import { AppContextData } from '../../context/app-context';

import MainPage1 from './MainPage1';
import MainPage2 from './MainPage2';
import Header from '../Header';
import Footer from '../Footer';
import Localization from '../../localization';
import '../../css/Main.css';

const Main = () => {
  const appContext = useContext(AppContextData);

  const lng = localStorage.getItem('tf_lang');
  let loc = new LocalizedStrings(Localization);
  loc.setLanguage(lng ? lng : 'en');

  if (appContext.orderLookupPage === false) {
    if (appContext.storeUrl) {
      window.location.href=appContext.storeUrl;
    } else {
      window.location.href='https://trackfree.io/';
    }
  }

  let mainPageData = '';
  if (appContext.mainPageThemeId && appContext.orderLookupPage) {
    mainPageData = <div className="storefront_body" style={{backgroundColor: appContext.styles.bodyBackground}}>
      <Header data={appContext} />
      {appContext.mainPageThemeId === 1 ?
        <MainPage1
          content={appContext.mainPageContent}
          styles={appContext.styles} />
      : <MainPage2
        data={appContext.mainPageSettings}
        styles={appContext.styles} />
      }
      {!appContext.hideMainPageFooter ?
        <Footer
          data={appContext.footerSettings}
          styles={appContext.styles} />
      : null}
    </div>
  }

  return (
    <div>
      {mainPageData}
    </div>
  );
}

export default Main;